import { useState } from 'react'

import { NavMenu, BadgeLabel, CustomButton } from 'components/common'

import useWorkspaceSideBar from 'hooks/useWorkspaceSideBar'

import CsvExportDialog from './CsvExportDialog'
import ReportAverage from './ReportAverage'
import ReportPlanAccuracy from './ReportPlanAccuracy'
import ReportProductivity from './ReportProductivity'

const Reports = () => {
  const [openCsvExportDialog, setOpenCsvExportDialog] = useState(false)
  const { workspaceId, workspaceName } = useWorkspaceSideBar('/reports')

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold">業務レポート</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={workspaceName} />
            </div>
          </div>
          <CustomButton outline onClick={() => setOpenCsvExportDialog(true)}>
            CSVエクスポート
          </CustomButton>
        </div>

        <ReportAverage workspaceId={Number(workspaceId)} />
        <ReportProductivity workspaceId={Number(workspaceId)} />
        <ReportPlanAccuracy workspaceId={Number(workspaceId)} />
        <CsvExportDialog
          key={openCsvExportDialog.toString()}
          open={openCsvExportDialog}
          setOpen={setOpenCsvExportDialog}
          currentWorkspaceId={Number(workspaceId)}
        />
      </div>
    </NavMenu>
  )
}

export default Reports
