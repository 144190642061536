import { isEqual } from 'es-toolkit'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardTitle, FormGroup } from 'reactstrap'

import { PLAN_UPDATE_MODE_TYPES } from 'api/plans/constants'
import type { WorkersPlanData, UpdatePlanSchedule, UpdatePlanBulk } from 'api/plans/types'

import { showSuccess } from 'slices/notificationSlice'
import { getPlanByDate, updatePlanBulkCreate, selectPlansStatus } from 'slices/plansSlice'
import { getTenantWithDate, selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { EditShiftsType } from 'components/Schedules/types'
import { List, DatePicker, CardSubmitFooter, CustomButton, NavMenu } from 'components/common'
import type { ListItem, EditSchedule } from 'components/common/types'
import { hasOverlappedSchedule } from 'components/common/utils'

import useAuthority from 'hooks/useAuthority'
import useBusinessTime from 'hooks/useBusinessTime'
import useDateChange from 'hooks/useDateChange'
import usePlans from 'hooks/usePlans'
import useQuery from 'hooks/useQuery'

import placeholder from 'images/allEmpty.svg'

import ShiftImport from './ShiftImport'
import WorkerSchedule from './WorkerSchedule'

import styles from './Shifts.module.scss'

const Shifts = () => {
  const [shiftImportOpen, setShiftImportOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [editGroups, setEditGroups] = useState<EditShiftsType[]>([])
  const [initGroups, setInitGroups] = useState<EditShiftsType[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { checkWorkspaceCreated } = useDateChange()

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { isRequesting, errorMessage, plans } = useSelector(selectPlansStatus, shallowEqual)
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)

  const query = useQuery()
  const { getWorkDate } = useBusinessTime({ tenantWithDate })
  const { workspaceId, date } = useMemo(() => {
    const id = Number(query.get('id')) || partialWorkspaces[0]?.id || 0
    const dateParams = query.get('date') || getWorkDate(moment().format('YYYY-MM-DD'))
    return { workspaceId: id, date: dateParams }
  }, [getWorkDate, partialWorkspaces, query])

  const {
    planWorkDate,
    shiftLastUpdatedAt,
    shiftLastUpdater,
    getEditSchedulesFromWorkPlan,
    getWorkerPlanFromUpdatePlanScheduleForShift,
    getEmptyShiftData,
  } = usePlans(tenantWithDate)

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  useEffect(() => {
    dispatch(getWorkspaceList({ workDate: date }))
  }, [dispatch, date])

  useEffect(() => {
    dispatch(getTenantWithDate())
  }, [dispatch])

  useEffect(() => {
    if (!workspaceId || !date) {
      return
    }
    dispatch(getPlanByDate(workspaceId, date))
    navigate(`/shifts?id=${workspaceId}&date=${date}`)
  }, [workspaceId, date, dispatch, navigate])

  const handleWorkspaceIdListChange = (selectedId: string | number) => {
    navigate(`/shifts?id=${selectedId}&date=${date}`, { replace: true })
  }

  const createEditGroupWorkers = useCallback(
    (workersPlan: WorkersPlanData[]) => {
      return workersPlan.map(worker => {
        const shiftSchedules: EditSchedule[] = getEditSchedulesFromWorkPlan(worker, false, true)

        return {
          workerId: worker.workerId,
          wmsMemberId: '0',
          name: worker.workerName,
          schedules: shiftSchedules.map(s => ({ ...s, editable: true })),
          partialHourlyProductivities: [],
        }
      })
    },
    [getEditSchedulesFromWorkPlan]
  )

  useEffect(() => {
    if (!plans) {
      return
    }
    setEditGroups(prev => {
      const selectedGroups: EditShiftsType[] = plans.groups
        .filter(g => !g.isSupported)
        .map(g => ({
          groupId: null,
          name: g.groupName ? g.groupName : '',
          supportedWorkspaceId: null,
          supportedWorkspaceName: null,
          workers: createEditGroupWorkers(g.workersPlan),
          isOpen: prev.find(editGroup => editGroup.name === g.groupName)?.isOpen ?? true,
        }))
        .filter(g => g.workers.length > 0)
      setInitGroups(selectedGroups)
      return selectedGroups
    })
  }, [plans, createEditGroupWorkers])

  useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      dispatch(getPlanByDate(workspaceId, date))
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId, date])

  const listItems: ListItem[] = useMemo(
    () =>
      partialWorkspaces.map(w => ({
        id: w.id,
        title: w.name,
      })),
    [partialWorkspaces]
  )

  const onSubmit = () => {
    if (!workspaceId) {
      return
    }

    setSubmitted(true)
    const updateSchedules = editGroups.flatMap(editData => {
      const initGroup = initGroups.find(ig => ig.name === editData.name && !isEqual(ig, editData))

      if (!initGroup) {
        return []
      }

      // worker schedules の追加or更新
      const updateWorkerIds = editData.workers
        .filter(w =>
          initGroup.workers.some(init => init.workerId === w.workerId && !isEqual(w.schedules, init.schedules))
        )
        .map(w => w.workerId)
      const updateWorker = editData.workers
        .filter(w => updateWorkerIds.includes(w.workerId))
        .flatMap(w => {
          const updateData = w.schedules.reduce((acc: UpdatePlanSchedule[], cur) => {
            acc.push({
              scheduleId: !cur.scheduleId || cur.scheduleId < 1 ? null : cur.scheduleId,
              schedule: {
                scheduleTypeId: cur.scheduleTypeId,
                supportWorkspaceId: cur.supportWorkspaceId,
                startAt: cur.startAt,
                duration: cur.duration,
                workerId: w.workerId,
                groupId: null,
              },
            })

            return acc
          }, [])

          return updateData.length === 0
            ? getEmptyShiftData(w.workerId) // 変更があるがシフトのデータが１つもない場合のデータを作成する
            : updateData
        })

      return updateWorker
    })

    const updatePlanDate: UpdatePlanBulk = {
      updateMode: PLAN_UPDATE_MODE_TYPES.SHIFT_PLAN,
      workersPlan: getWorkerPlanFromUpdatePlanScheduleForShift(updateSchedules),
    }
    dispatch(updatePlanBulkCreate(workspaceId, date, updatePlanDate))
  }

  const onCancel = () => {
    setEditGroups(initGroups)
  }

  const handleShiftImport = () => {
    setShiftImportOpen(false)
    dispatch(
      showSuccess({
        successMessage: 'シフト情報のアップロードに成功しました。反映までしばらく時間がかかる場合があります。',
      })
    )
  }

  const handleDatePickerChange = (input: string | undefined) => {
    const formatDate = moment(input).local().format('YYYY-MM-DD')
    if (!input || formatDate === date) {
      return
    }
    checkWorkspaceCreated(formatDate, workspaceId, `/shifts?id=${workspaceId}&date=${formatDate}`)
  }

  const unchanged = useMemo(
    () =>
      isEqual(
        initGroups.flatMap(g => g.workers),
        editGroups.flatMap(g => g.workers)
      ),
    [initGroups, editGroups]
  )

  const disabled = useMemo(
    () => editGroups.some(editGroup => editGroup.workers.some(worker => hasOverlappedSchedule(worker.schedules, true))),
    [editGroups]
  )
  const handleWorkPlanButtonClick = () => navigate(`/schedules/${workspaceId}/${planWorkDate}`)

  const hasShift = useMemo(() => {
    if (!plans) {
      return false
    }

    return plans.groups.filter(g => g.workersPlan.some(w => w.workShifts.some(s => s && s > 0))).length > 0
  }, [plans])

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="font-x-large fw-bold align-self-center">シフト管理</div>
          <CustomButton icon="plus" outline disabled={isReadOnlyWorkspace} onClick={() => setShiftImportOpen(true)}>
            シフトインポート
          </CustomButton>
        </div>
        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={`h-100 ${styles.list}`}>
              {listItems.length > 0 ? (
                <List items={listItems} selectedId={workspaceId} onAction={handleWorkspaceIdListChange} />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">メンバーがいません</div>
                    <div>メンバーを追加して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {!workspaceId ? (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle fw-bold py-4">メンバーが選択されていません</div>
                    <div>メンバーを選択して、詳細情報を編集しましょう。</div>
                  </div>
                </CardBody>
              ) : (
                <div className="h-100">
                  <div className={styles.shiftDetail}>
                    <CardBody className="h-100">
                      <div className={styles.top}>
                        <CardTitle className="font-large fw-bold">シフト情報</CardTitle>
                        <FormGroup row className="pe-3">
                          <Col md={6} className="d-flex align-items-center">
                            <div className="text-nowrap me-3">日付を選択</div>
                            <DatePicker id="dateSelect" value={date} onChange={handleDatePickerChange} />
                          </Col>
                          <Col className="p-0">
                            <CustomButton
                              icon="schedule"
                              iconPosition="left"
                              outline
                              onClick={handleWorkPlanButtonClick}
                              className="w-100 d-flex justify-content-end p-0"
                              disabled={!hasShift}
                              name="shifts-move-work-plan"
                            >
                              作業計画
                            </CustomButton>
                          </Col>
                        </FormGroup>
                      </div>
                      <div className={`${styles.workerSchedule} my-3`}>
                        <WorkerSchedule
                          date={date}
                          editGroups={editGroups}
                          onEditGroupsChange={setEditGroups}
                          tenantWithDate={tenantWithDate}
                        ></WorkerSchedule>
                      </div>
                    </CardBody>
                  </div>
                  <CardSubmitFooter
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    cancelDisabled={unchanged}
                    submitDisabled={unchanged || disabled}
                    updatedAt={shiftLastUpdatedAt}
                    updatedBy={shiftLastUpdater}
                  />
                </div>
              )}
            </Card>
          </Col>
        </Row>

        <ShiftImport
          isOpen={shiftImportOpen}
          onSuccess={handleShiftImport}
          onCancel={() => setShiftImportOpen(false)}
        />
      </div>
    </NavMenu>
  )
}

export default Shifts
