import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

import placeholder from './placeholder.svg'

export const DEFAULT_POINT_PADDING = 0.1

export type Series = {
  type: string
  color: string
  name: string
  opacity?: number
  data: (number | null)[]
  custom?: Highcharts.Dictionary<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type ChartOptionType = {
  xAxis: {
    data: string[]
  }
  yAxis: Series[]
}
type ChartProp = {
  options: Highcharts.Options
}

export type StackChartOptionProps = {
  seriesData: Highcharts.SeriesOptionsType[]
  categories: string[]
  pointPadding?: number
}

type DateFormatType = 'hour' | 'day'

type DashStyle = 'Dash' | 'Solid'

const getDateFormat = (data: string[]): string => {
  const beginning = moment(data[0])
  const end = moment(data.slice(-1)[0])
  if (end.isSameOrBefore(beginning.add(1, 'd'))) {
    return 'hour'
  }
  return 'day'
}
export const getMaxYAxisValue = (series: Series[]) => {
  return series.reduce((acc, s) => {
    const values = s.data.filter(value => value !== null) as number[]
    return Math.max(acc, ...values)
  }, 0)
}

// "#868b8d" -> "134,139,141"
const hexToRgb = (hex: string): string => {
  const match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return match ? [parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16)].join(',') : ''
}

const createSeries = (series: Series[], dashStyle: DashStyle): Highcharts.SeriesOptionsType[] => {
  return series.map(s => {
    if (s.type === 'line') {
      return {
        type: 'line',
        color: s.color,
        name: s.name,
        opacity: s.opacity ?? 1,
        marker: {
          enabled: true,
        },
        dashStyle,
        data: s.data,
        custom: s.custom,
      }
    }

    // type === 'area'
    return {
      type: 'area',
      color: s.color,
      name: s.name,
      opacity: s.opacity ?? 1,
      marker: {
        enabled: true,
      },
      data: s.data,
      custom: s.custom,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, `rgba(${hexToRgb(s.color)}, 0.7)`],
          [1, `rgba(${hexToRgb(s.color)}, 0)`],
        ],
      },
    }
  })
}

export const createLineChartOptions = (
  options: ChartOptionType,
  dashStyle: DashStyle,
  dateFormat?: DateFormatType
): Highcharts.Options => {
  const duration = dateFormat ?? getDateFormat(options.xAxis.data)
  const tooltipFormat = duration === 'hour' ? 'HH:mm' : 'YYYY/MM/DD'
  const xAxisFormat = duration === 'hour' ? 'HH:mm' : 'MM/DD'
  return {
    chart: {
      renderTo: 'container',
      marginBottom: 30,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      style: {
        color: '#ffffff',
      },
      backgroundColor: '#000000',
      borderColor: '#000000',
      useHTML: true,
      formatter() {
        return `<div style='text-align:center'>${moment(this.x).format(tooltipFormat)}<br>${Math.floor(
          this.y || 0
        ).toLocaleString()}</div>`
      },
    },
    xAxis: {
      type: 'datetime',
      categories: options.xAxis.data,
      labels: {
        formatter() {
          return moment(this.value).format(xAxisFormat)
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter() {
          return this.value.toLocaleString()
        },
      },
      max: getMaxYAxisValue(options.yAxis),
      min: 0,
      tickAmount: 6,
    },
    plotOptions: {
      series: {
        connectNulls: true,
      },
      area: {
        marker: {
          radius: 3,
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
      line: {
        marker: {
          radius: 3,
        },
      },
    },
    series: createSeries(options.yAxis, dashStyle),
    accessibility: {
      enabled: false,
    },
  }
}

export const createBarChartOptions = (
  graphData: { data: number; label: string }[],
  onClick: (label: string) => void
): Highcharts.Options => {
  const absData = graphData.map(d => Math.abs(d.data))
  const maxData = Math.max(...absData)

  const yLabel = graphData.map(({ label }) => label)

  // 色分けするため、プラスとマイナスに分割
  const { plusData, minusData } = graphData.reduce(
    (acc, cur) => {
      const isPlus = cur.data >= 0

      // eslint-disable-next-line no-shadow
      const plusData = acc.plusData.concat(isPlus ? cur.data : 0)
      // eslint-disable-next-line no-shadow
      const minusData = acc.minusData.concat(isPlus ? 0 : cur.data)
      return { plusData, minusData }
    },
    { plusData: [], minusData: [] } as {
      plusData: number[]
      minusData: number[]
    }
  )

  return {
    chart: {
      type: 'bar',
    },
    // タイトルを非表示
    title: {
      text: undefined,
    },
    // 凡例を非表示にする
    legend: {
      enabled: false,
    },
    // Highchartsのロゴを非表示にする
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        categories: yLabel,
        reversed: false,
        labels: {
          // y軸のラベルの省略
          style: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        },
      },
    ],
    yAxis: {
      title: {
        text: undefined,
      },
      // y軸のラベルの表示を変更する
      labels: {
        formatter: function () {
          return this.value.toString()
        },
      },
      min: maxData * -1,
      max: maxData,
      // y軸の縦線の間隔を変更する
      tickInterval: 5,
    },
    // プラスとマイナスのグラフを直線で並べる
    plotOptions: {
      series: {
        stacking: 'normal',
        events: {
          click: function (e) {
            onClick(String(e.point.category))
          },
        },
      },
    },
    tooltip: {
      style: {
        color: '#ffffff',
      },
      backgroundColor: '#000000',
      borderColor: '#000000',
      useHTML: true,
      formatter() {
        return `
          <div style="text-align:center">${this.x}<br>${this.y}</div>`
      },
    },
    series: [
      {
        type: 'bar',
        color: 'var(--bs-danger)',
        data: minusData,
      },
      {
        type: 'bar',
        color: 'var(--bs-primary)',
        data: plusData,
      },
    ],
    accessibility: {
      enabled: false,
    },
  }
}

export const createStackedChartOptions = (optionProps: StackChartOptionProps): Highcharts.Options => {
  return {
    chart: {
      type: 'column',
    },
    // タイトルを非表示
    title: {
      text: undefined,
    },
    // 凡例を非表示にする
    legend: {
      enabled: false,
    },
    // Highchartsのロゴを非表示にする
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: optionProps.categories,
      reversed: false,
      labels: {
        formatter() {
          return `${this.value}`
        },
      },
    },
    yAxis: [
      {
        title: {
          text: undefined,
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: optionProps.pointPadding ?? DEFAULT_POINT_PADDING,
      },
      series: {
        states: {
          inactive: {
            enabled: false,
          },
        },
        borderWidth: 0,
      },
    },
    tooltip: {
      style: {
        color: '#ffffff',
      },
      backgroundColor: '#000000',
      borderColor: '#000000',
      useHTML: true,
      formatter() {
        return `<div style="text-align:center">${this.x}<br>${this.y}</div>`
      },
      positioner: function (this, labelWidth, labelHeight, point) {
        const tooltipX = point.plotX
        const tooltipY = this.chart.plotHeight + 20
        return {
          x: tooltipX,
          y: tooltipY,
        }
      },
      outside: true,
    },
    series: optionProps.seriesData,
    accessibility: {
      enabled: false,
    },
  }
}

const Chart = (props: ChartProp) => {
  const isEmptyOptions = !props.options || Object.keys(props.options).length === 0
  return (
    <div>
      {isEmptyOptions ? (
        <img src={placeholder} alt="placeholder" className="w-100" />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={props.options} immutable={true} />
      )}
    </div>
  )
}

export default Chart
