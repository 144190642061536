import dayjs from 'dayjs'
import { merge, sortBy, uniqBy } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import type { HourlyPlanAccuracyRow } from 'api/reports/types'

import { getReportHourlyPlanAccuracy, selectReportsStatus } from 'slices/reportsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { createXAxis, colorTypeToCode } from 'components/Dashboard/utils'
import { NavMenu, BadgeButton, Chart, ScrollToTopOnMount } from 'components/common'
import type { Series } from 'components/common/types'
import { createLineChartOptions } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import type { XAxisOptions } from 'highcharts'

const PlanAccuracyDetail = () => {
  const params = useParams<'workspaceId' | 'date'>()
  const workspaceId = Number(params.workspaceId)
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { businessStartTime, businessEndTime, getWorkDate, getTimeOver24h } = useBusinessTime({ tenantWithDate })
  const date = useMemo(() => getWorkDate(String(params.date)), [params, getWorkDate])
  const [selectedBadgeKeys, setSelectedBadgeKeys] = useState<number[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  useEffect(() => {
    if (!workspaceId || !date) {
      return
    }
    dispatch(getReportHourlyPlanAccuracy(workspaceId, date))
  }, [dispatch, workspaceId, date])

  const { hourlyPlanAccuracies } = useSelector(selectReportsStatus, shallowEqual)

  const formatDate = useMemo(() => dayjs(date).locale('ja').format('YYYY/MM/DD（ddd）'), [date])

  const badgeItems = useMemo(() => {
    const hourlyPlanAccuracyItems = hourlyPlanAccuracies.map(planAccuracy => ({
      color: planAccuracy.scheduleTypeColor,
      key: planAccuracy.scheduleTypeId,
      label: planAccuracy.scheduleTypeName,
    }))
    const hourlyPlanAccuraciesUniqueByKey = uniqBy(hourlyPlanAccuracyItems, data => data.key)
    return sortBy(hourlyPlanAccuraciesUniqueByKey, ['key'])
  }, [hourlyPlanAccuracies])

  useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const chartOptions = useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))

    // 20:45のようなケースでは20:00に集計されるため、分を切り捨ててxAxisを作成する
    const fixBusinessEndTime = `${businessEndTime.split(':')[0]}:00`
    const xAxisData = createXAxis(businessStartTime, fixBusinessEndTime, false, date)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const unit = hourlyPlanAccuracies.find(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)?.unit || ''
      const planAccuracyRows = hourlyPlanAccuracies
        .filter(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)
        .flatMap(planAccuracy => planAccuracy.data)

      // areaの上にlineを表示するためにareaを先にしておく
      const archivePlanData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        // row.time も time も UTC なので文字列として比較しても大丈夫そうだが、安全のため new Date を通す
        const target = planAccuracyRows.find(row => new Date(row.date).getTime() === new Date(time).getTime())
        return target ? target.archivePlanCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: archivePlanData,
        name: graphBadge.label,
        custom: { planAccuracyRows, unit }, // tooltipに最終計画とアーカイブのペアを表示するため、customに格納
      })

      const lastPlanData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = planAccuracyRows.find(row => new Date(row.date).getTime() === new Date(time).getTime())
        return target ? target.lastPlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: lastPlanData,
        name: graphBadge.label,
        custom: { planAccuracyRows, unit }, // tooltipに最終計画とアーカイブのペアを表示するため、customに格納
      })
    })

    const options = createLineChartOptions(
      {
        xAxis: {
          data: xAxisData,
        },
        yAxis,
      },
      'Dash'
    )
    merge(options, {
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
      tooltip: { useHTML: true },
    })
    options?.series?.forEach(option => {
      if (option.type === 'area') {
        merge(option, { type: 'line' })
      }
    })
    ;(options.xAxis as XAxisOptions).labels!.formatter = function () {
      if (this.isFirst) {
        return dayjs(this.value).format('HH:mm')
      }
      return getTimeOver24h(dayjs(this.value).format('HH:mm'))
    }

    options.tooltip!.formatter = function () {
      const planAccuracyRows: HourlyPlanAccuracyRow[] = this.series.options.custom?.planAccuracyRows
      const unit = this.series.options.custom?.unit
      const row = planAccuracyRows.find(r => dayjs(r.date).isSame(this.x))
      const lastPlan = row?.lastPlanCount ? row.lastPlanCount.toLocaleString() : '-'
      const archivePlan = row?.archivePlanCount ? row.archivePlanCount.toLocaleString() : '-'
      return `<div style="display: flex; flex-direction: column; align-items: center;">
                <div>${getTimeOver24h(dayjs(this.x).format('HH:mm'), this.point.index === 0)}</div>
                <div>最終計画：${lastPlan} ${unit}</div>
                <div>アーカイブ：${archivePlan} ${unit}</div>
              </div>`
    }
    return options
  }, [hourlyPlanAccuracies, badgeItems, selectedBadgeKeys, date, businessStartTime, businessEndTime, getTimeOver24h])

  return (
    <>
      <ScrollToTopOnMount />

      <NavMenu>
        <div className="mt-3 mx-3">
          <Link
            to={`/reports/${workspaceId}`}
            className="d-flex align-items-center text-secondary pb-2 text-decoration-none"
          >
            <i className="icf-chevron_left" />
            戻る
          </Link>
          <div className="d-flex mb-3">
            <div className="font-x-large fw-bold me-2">{formatDate}の計画変更詳細</div>
          </div>

          <Card className="mb-3">
            <CardBody className="fw-bold">{formatDate}の詳細</CardBody>
            <CardBody>
              <Chart options={chartOptions} />
            </CardBody>
            <CardBody className="d-flex row my-2">
              <BadgeButton
                items={badgeItems}
                selected={selectedBadgeKeys}
                onChange={selected => setSelectedBadgeKeys(selected)}
              />
            </CardBody>
          </Card>
        </div>
      </NavMenu>
    </>
  )
}

export default PlanAccuracyDetail
