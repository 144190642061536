import { useState, useEffect, useMemo } from 'react'

import type { TenantData } from 'api/tenants/types'
import type { ColorType } from 'api/types'

import { TimeScale } from 'components/common/ShiftBar/TimeScale'

import TableScale from './TableScale'

import styles from './AssignedNumberTable.module.scss'

import type { RefObject } from 'react'

type ItemBaseType = {
  id: number
  name: string
  counts: Map<number, number>
}

export type ItemScheduleType = ItemBaseType & {
  color: ColorType
}
export type TableItemType = ItemBaseType & {
  schedules: ItemScheduleType[]
}

type Props = {
  businessStartTime: string
  businessEndTime: string
  items: TableItemType[]
  close?: boolean
  total?: boolean
  divElement?: RefObject<HTMLDivElement>
  onScroll?: () => void
  tenantWithDate?: TenantData
}

const AssignedNumberTable = (props: Props) => {
  const { businessStartTime, businessEndTime, items, close, total, divElement, onScroll, tenantWithDate } = props
  const [closeWorkspaces, setCloseWorkspaces] = useState<number[]>([])

  const handleWorkspaceNameClick = (workspaceId: number) => {
    const newCloseWorkspaces = closeWorkspaces.includes(workspaceId)
      ? closeWorkspaces.filter(id => id !== workspaceId)
      : closeWorkspaces.concat([workspaceId])

    setCloseWorkspaces(newCloseWorkspaces)
  }
  const isOpen = (workspaceId: number) => !closeWorkspaces.includes(workspaceId)

  const getTotal = (counts: Map<number, number>) =>
    Array.from(counts.values()).reduce((acc: number, num: number) => (acc += num), 0)

  const totalCounts = useMemo(() => {
    const counts = new Map<number, number>()
    if (total) {
      return items.reduce((acc, cur) => {
        const start = Number(businessStartTime.slice(0, 2))
        const end = Number(businessEndTime.slice(0, 2))
        for (let index = start; index < end; index++) {
          const accCount = acc.get(index) || 0
          const curCount = cur.counts.get(index) || 0
          acc.set(index, accCount + curCount)
        }
        return acc
      }, counts)
    }
    return counts
  }, [businessEndTime, businessStartTime, items, total])

  useEffect(() => {
    close && setCloseWorkspaces(items.map(data => data.id))
  }, [close, items])

  return (
    <div ref={divElement} className={styles.tableWrapper} onScroll={onScroll}>
      <table>
        <thead>
          <tr className={styles.timeHeader}>
            <td className={`bg-secondary-pale px-4 ${styles.tableWorkHeader}`}>作業</td>
            <td className="p-0">
              <TimeScale round tenantWithDate={tenantWithDate} />
            </td>
            <td className={`bg-secondary-pale px-4 ${styles.tableTotalHeader}`}>合計</td>
          </tr>
        </thead>
        {items.map((workspace, index) => (
          <tbody key={`workspace-${workspace.id}-${index}`}>
            <tr className={styles.tableRow}>
              <td
                className={styles.tableWorkHeader}
                role="button"
                onClick={() => handleWorkspaceNameClick(workspace.id)}
              >
                <div className="d-flex align-items-center px-3">
                  <i className={`icf-carot_${isOpen(workspace.id) ? 'down' : 'right'} me-1`} />
                  <div className="text-truncate">{workspace.name}</div>
                </div>
              </td>
              <td className={styles.tableContent}>
                <TableScale items={workspace.counts} tenantWithDate={tenantWithDate} />
              </td>
              <td className={styles.tableTotalHeader}>{getTotal(workspace.counts).toFixed(2)}</td>
            </tr>
            {isOpen(workspace.id) &&
              workspace.schedules.map((schedule, idx) => (
                <tr className={styles.tableRow} key={`number-${schedule.id}-${idx}`}>
                  <td className={styles.tableWorkHeader}>
                    <div className="d-flex align-items-center px-3">
                      <div>
                        <div className={`${styles.square} bg-${schedule.color} me-1`}></div>
                      </div>
                      <div className="text-truncate">{schedule.name}</div>
                    </div>
                  </td>
                  <td className={styles.tableContent}>
                    <TableScale items={schedule.counts} tenantWithDate={tenantWithDate} />
                  </td>
                  <td className={styles.tableTotalHeader}>{getTotal(schedule.counts).toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
        ))}
        {total && (
          <tbody>
            <tr className={styles.tableRow}>
              <td className={styles.tableWorkHeader}>
                <div className="px-4">合計</div>
              </td>
              <td className={styles.tableContent}>
                <TableScale items={totalCounts} tenantWithDate={tenantWithDate} />
              </td>
              <td className={styles.tableTotalHeader}>{getTotal(totalCounts).toFixed(2)}</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

export default AssignedNumberTable
