import { sortBy, last } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

import { showError, showSuccess } from 'slices/notificationSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import { BadgeLabel, CardSubmitFooter, CustomButton, List, NavMenu } from 'components/common'
import type { ListItem } from 'components/common/types'

import placeholderImage from 'images/allEmpty.svg'

import styles from './ListView.module.scss'

import type { Dispatch, SetStateAction, ReactNode } from 'react'
import type { AppThunk } from 'store'

type Props = {
  title: string
  badgeLabel?: string //undefinedの場合badge非表示
  addButton?: { label: string; onClick: () => void }
  maxItems?: number
  listItems: ListItem[]
  onSelectItem: (id: number) => void
  newListItem: ListItem
  emptyList: {
    title: string
    text: string
    buttonLabel: string
    onClick: () => void
  }
  emptyDetail: {
    title: string
    text: string
  }
  footer: {
    updatedBy?: string | null
    updatedAt?: string
    cancelDisabled?: boolean
    submitDisabled?: boolean
    submitLabel?: string
    onSubmit: () => void
    onCancel: () => void
  }
  onSuccess?: () => void
  onError?: () => void
  isRequesting: boolean
  errorMessage: string
  selectedId: number | undefined
  setSelectedId: Dispatch<SetStateAction<number | undefined>>
  newItemId: number
  getListFunc: (callback: (newList: { id: number; name: string }[]) => void) => AppThunk
  getDetailFunc: (id: number) => AppThunk
  deleteDialog?: (onSuccess: () => void) => ReactNode
  storeId: number | undefined
  children: ReactNode
}

const NEW_ITEM_ID = 0

// 左リスト､右詳細のレイアウトでリスト選択処理とレイアウトを担当する
// childに右詳細のレイアウトを定義する
// setSelectedIdはこのコンポーネントで責任を持ち､ここ以外で実装しないようにする
const ListDetailView = ({
  title,
  badgeLabel,
  addButton,
  maxItems,
  listItems: originalListItems,
  onSelectItem,
  newListItem,
  emptyList,
  emptyDetail,
  footer,
  onSuccess,
  onError,
  isRequesting,
  errorMessage,
  selectedId,
  setSelectedId,
  newItemId = NEW_ITEM_ID,
  getListFunc,
  getDetailFunc,
  deleteDialog,
  storeId,
  children,
}: Props) => {
  const [submitted, setSubmitted] = useState(false)

  const dispatch = useDispatch()

  const fetchDetail = useCallback(
    (isSelectFirstItem: boolean) => (newList: { id: number; name: string }[]) => {
      if (newList.length === 0) {
        return
      }

      setSelectedId(prev => {
        const targetId = isSelectFirstItem ? sortBy(newList, ['name'])[0].id : prev || last(newList)!.id
        dispatch(getDetailFunc(targetId))
        return targetId
      })
    },
    [dispatch, getDetailFunc, setSelectedId]
  )

  useEffect(() => {
    dispatch(getListFunc(fetchDetail(true)))
  }, [dispatch, fetchDetail, getListFunc])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    if (errorMessage === '') {
      onSuccess?.()
      dispatch(getListFunc(fetchDetail(false)))
      dispatch(showSuccess())
    } else {
      onError?.()
      // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
      if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        dispatch(showError())
      }
    }

    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess, onError, getListFunc, fetchDetail])

  const listItems = useMemo(() => {
    if (selectedId === newItemId) {
      return originalListItems.concat(newListItem)
    }
    return originalListItems
  }, [newItemId, newListItem, originalListItems, selectedId])

  const hasItems = useMemo(() => listItems.length > 0, [listItems])

  useEffect(() => {
    if (originalListItems.length === 0) {
      return
    }

    setSelectedId(prev => {
      // 初期化
      if (prev === undefined) {
        return Number(originalListItems[0].id)
      }

      return prev
    })
  }, [originalListItems, setSelectedId])

  const disabledAddButton = useMemo(() => {
    if (selectedId === newItemId) {
      return true
    }
    if (maxItems) {
      return listItems.length >= maxItems
    }
    return true
  }, [listItems.length, maxItems, newItemId, selectedId])

  const handleDeleteSuccess = useCallback(() => {
    dispatch(showSuccess())
    dispatch(getListFunc(fetchDetail(true)))
  }, [dispatch, fetchDetail, getListFunc])

  const handleItemSelected = useCallback(
    (id: string | number) => {
      if (id === selectedId) {
        return
      }
      setSelectedId(Number(id))
      onSelectItem(Number(id))
    },
    [onSelectItem, selectedId, setSelectedId]
  )

  return (
    <NavMenu>
      <div className="mt-3 mx-3">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div className="font-x-large flex-grow-1 fw-bold">{title}</div>
              <div className="px-2 align-self-center">{badgeLabel && <BadgeLabel label={badgeLabel} />}</div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {maxItems && (
                <div className="me-2 text-gray">
                  {listItems.length} / {maxItems} 利用中
                </div>
              )}

              {addButton && (
                <CustomButton
                  icon="plus"
                  onClick={() => {
                    addButton.onClick?.()
                    setSelectedId(newItemId)
                  }}
                  className="ms-2"
                  disabled={disabledAddButton}
                >
                  {addButton.label || ''}
                </CustomButton>
              )}
            </div>
          </div>
        </div>
        <Row className={styles.row}>
          <Col md={4} className="h-100">
            <Card className={styles.list}>
              {hasItems ? (
                <List items={listItems} selectedId={selectedId} onAction={handleItemSelected} />
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholderImage} alt="" />
                    <div className="font-middle fw-bold py-4">{emptyList.title}</div>
                    <div>{emptyList.text}</div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={emptyList.onClick}>
                      {emptyList.buttonLabel}
                    </Button>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col md={8} className="h-100">
            <Card className="h-100">
              {hasItems ? (
                <>
                  {children}
                  <CardSubmitFooter
                    onCancel={() => {
                      setSelectedId(storeId)
                      footer.onCancel()
                    }}
                    onSubmit={() => {
                      footer.onSubmit()
                      setSubmitted(true)
                    }}
                    submitDisabled={footer.submitDisabled}
                    cancelDisabled={footer.cancelDisabled}
                    updatedBy={selectedId === newItemId ? undefined : footer.updatedBy}
                    updatedAt={selectedId === newItemId ? undefined : footer.updatedAt}
                  />
                </>
              ) : (
                <CardBody className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholderImage} alt="" />
                    <div className="font-middle fw-bold py-4">{emptyDetail.title}</div>
                    <div>{emptyDetail.text}</div>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {deleteDialog?.(handleDeleteSuccess)}
      </div>
    </NavMenu>
  )
}

export default ListDetailView
