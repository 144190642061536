import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/optimization/optimization'
import type {
  GetLocationConfigResponse,
  MagiQannealScheduleResponse,
  PlanType,
  V2AttendanceType,
} from 'api/optimization/types'
import type { PartialScheduleTypeData } from 'api/schedule_types/types'
import type { PartialWorkerData } from 'api/workers/types'
import type { PartialWorkspaceData } from 'api/workspaces/types'

import type { AllGroupType } from 'slices/groupsSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as Spinner from 'slices/spinnerSlice'

import { makeErrorMessage } from './utils'

import type { PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type OptimizationState = {
  isRequesting: boolean
  errorMessage: string
  optimizationError: boolean
  data?: GetLocationConfigResponse
  optimizedSchedule?: MagiQannealScheduleResponse
}

const initialState: OptimizationState = {
  isRequesting: false,
  errorMessage: '',
  optimizationError: false,
  data: undefined,
  optimizedSchedule: undefined,
}

export const optimizationSlice = createSlice({
  name: 'optimization',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearError: state => {
      state.errorMessage = ''
      state.optimizationError = false
    },
    apiFailure: state => {
      state.isRequesting = false
      state.optimizationError = true
    },
    apiSuccess: state => {
      state.isRequesting = false
    },
    getDataAtSuccess: (state, action: PayloadAction<GetLocationConfigResponse>) => {
      state.isRequesting = false
      state.data = action.payload
    },
    getMagiQannealScheduleSuccess: (state, action: PayloadAction<MagiQannealScheduleResponse>) => {
      state.isRequesting = false
      state.optimizedSchedule = action.payload
    },
    healthcheckError: (state, action: PayloadAction<string>) => {
      state.isRequesting = false
      state.optimizationError = true
      state.errorMessage = action.payload
    },
  },
})

export const {
  startRequest,
  clearError,
  apiFailure,
  apiSuccess,
  getDataAtSuccess,
  getMagiQannealScheduleSuccess,
  healthcheckError,
} = optimizationSlice.actions

type OptimizationAction = {
  type: string
  payload?: string | NetworkErrorDialog.NetworkErrorPayload
}
type OptimizationError = {
  errorMessage: string
}
const handleErrorResponse = (
  res: AxiosError<OptimizationError>,
  dispatch: ThunkDispatch<RootState, unknown, OptimizationAction>
) => {
  const errorCode = makeErrorMessage(res)
  const errorMessage = res.response?.data?.errorMessage || '問題が発生しました。管理者にお問い合わせください。'
  dispatch(NetworkErrorDialog.open({ code: errorCode, errorMessage }))
  dispatch(apiFailure())
}

export const getLocationConfig =
  (apiKey: string, tenant: string, location: string, datetime: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    try {
      const res = await API.getLocationConfig(apiKey, tenant, location, datetime)
      dispatch(getDataAtSuccess(res))
    } catch (res) {
      handleErrorResponse(res as AxiosError<OptimizationError>, dispatch)
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const createDataAt =
  (
    apiKey: string,
    tenant: string,
    location: string,
    datetime: string,
    planned: PlanType[],
    processed: PlanType[],
    predicted: PlanType[],
    attendance: V2AttendanceType[]
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    try {
      await API.createTargetValues(apiKey, tenant, location, { datetime, planned, processed, predicted })
      await API.createAttendance(apiKey, tenant, location, { datetime, attendance })
      dispatch(apiSuccess())
    } catch (error) {
      handleErrorResponse(error as AxiosError<OptimizationError>, dispatch)
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const getMagiQannealSchedule =
  (apiKey: string, tenant: string, location: string, date: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    try {
      const res = await API.getMagiQannealSchedule(apiKey, tenant, location, date)
      dispatch(getMagiQannealScheduleSuccess(res))
    } catch (res) {
      handleErrorResponse(res as AxiosError<OptimizationError>, dispatch)
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const healthCheck =
  (apiKey: string, tenant: string, locations: string[]): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())

    try {
      await Promise.all(locations.map(location => API.getLocation(apiKey, tenant, location)))
      dispatch(apiSuccess())
    } catch (res) {
      // health check に失敗した場合は errorMessage にメッセージを設定
      const errorMessage =
        (res as AxiosError<OptimizationError>).response?.data?.errorMessage ||
        '問題が発生しました。管理者にお問い合わせください。'
      dispatch(healthcheckError(errorMessage))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const createRoster =
  (
    apiKey: string,
    tenant: string,
    location: string,
    datetime: string,
    partialWorkspaces: PartialWorkspaceData[],
    allGroups: AllGroupType[],
    workers: PartialWorkerData[],
    allScheduleTypes: PartialScheduleTypeData[]
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    dispatch(Spinner.start())
    try {
      const config = await API.getLocationConfig(apiKey, tenant, location, datetime)
      const targetWorkIds = config?.works.map(w => w.workId) ?? []
      const roster = workers.map(w => {
        // skillsは人事生産性の値を返す
        // 実績なし作業の場合は0を返す
        const skills = targetWorkIds.map(id => {
          const productivity = w.partialHourlyProductivities?.find(p => p.scheduleTypeId === id)
          const target = allScheduleTypes.find(s => s.id === id)
          return {
            workId: id,
            skill: target ? productivity?.value || target.defaultProductivity || 0 : 0,
          }
        })
        const workspace = partialWorkspaces.find(ws => w.workspaceId === ws.id)
        const targetGroup = allGroups.find(g => g.workspaceId === w.workspaceId)?.groups.find(g => g.id === w.groupId)
        // `${ワークスペース名}_${グループ名}` を返す
        // ワークスペース未所属の場合は null、グループ未所属の場合は`${ワークスペース名}_未所属` を返す
        const group = workspace ? `${workspace.name}_${targetGroup?.name ?? '未所属'}` : null

        // flagsはリーダーかどうかとスキルIDを返す
        const flags = []
        if (w.groupLeader) {
          flags.push('leader')
        }
        if (w.skillIds) {
          flags.push(...w.skillIds.map(String))
        }

        return {
          workerId: w.wmsMemberId ?? '',
          workerName: w.name,
          group,
          skills,
          flags: flags.length > 0 ? flags : undefined,
        }
      })
      await API.createRoster(apiKey, tenant, location, { datetime, roster })
      dispatch(apiSuccess())
    } catch (e) {
      handleErrorResponse(e as AxiosError<OptimizationError>, dispatch)
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const selectOptimizationStatus = (state: RootState) => ({ ...state.optimization })

export default optimizationSlice.reducer
