import { floor } from 'es-toolkit/compat'
import { Card, CardBody } from 'reactstrap'

import BadgeLabel from 'components/common/BadgeLabel/BadgeLabel'

type Props = {
  amount: string
  ratio?: string
  badgeLabel: string
  badgeColor: string
  className?: string
  unit: string
}

const AmountCard = ({ amount, ratio, badgeLabel, badgeColor, className, unit }: Props) => {
  return (
    <Card className={className}>
      <CardBody>
        <div className="d-flex">
          <BadgeLabel label={badgeLabel} color={badgeColor} />
        </div>
        <div className="d-flex flex-wrap justify-content-center align-items-baseline pt-2 px-3">
          <div className="font-x-large fw-bold me-1">{amount}</div>
          <div>{unit}</div>
          {ratio && (
            <>
              <div className="font-x-large fw-bold ms-3 me-1">{floor(Number(ratio), 2).toFixed(2)}</div>
              <div>%</div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default AmountCard
