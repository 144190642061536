import { useCallback } from 'react'

import type { TenantData } from 'api/tenants/types'

import { CELL_WIDTH } from 'components/common/constants'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './TableScale.module.scss'

type Props = {
  items: Map<number, number> | Map<number, JSX.Element>
  tenantWithDate?: TenantData
}
const TableScale = ({ items, tenantWithDate }: Props) => {
  const { businessHourBlocks } = useBusinessTime({ tenantWithDate })
  const width = CELL_WIDTH * 4

  const count = useCallback((item: number | JSX.Element | undefined) => {
    if (typeof item === 'number') {
      return item.toFixed(2)
    }
    if (!item) {
      return '0.00'
    }
    return item
  }, [])

  return (
    <table>
      <tbody>
        <tr className={styles.container} style={{ width: width * businessHourBlocks.length }}>
          {businessHourBlocks.map(time => (
            <td key={time} className={styles.box} style={{ width }}>
              {count(items.get(time))}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default TableScale
