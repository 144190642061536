import moment from 'moment/moment'
import { useMemo } from 'react'

import useBusinessTime from './useBusinessTime'
import useQuery from './useQuery'

// pathに含まれるDateクエリパラメータを取得する

const useDateQuery = () => {
  const { getWorkDate } = useBusinessTime()
  const query = useQuery()
  // dateクエリパラメーターがない場合はgetWorkDateで営業時間前チェックをする
  return useMemo(() => query.get('date') || getWorkDate(moment().format('YYYY-MM-DD')), [getWorkDate, query])
}

export default useDateQuery
