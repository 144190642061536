import type { CreateResponse, DeleteResponse, UpdateResponse } from 'api/types'

import { getUUID } from 'components/common/utils'

import { axiosClientWithTenantId } from 'AxiosClientProvider'

import type {
  OriginalScheduleTypeResponse,
  RelatedScheduleTypeListResponse,
  ScheduleTypeEditDataType,
  ScheduleTypeListResponse,
  ScheduleTypeResponse,
} from './types'

export const getScheduleTypeList = async (
  workspaceId: number,
  workDate?: string
): Promise<ScheduleTypeListResponse> => {
  const url = `/workspaces/${workspaceId}/schedule-types`

  const paramData = workDate ? { 'work-date': workDate } : {}
  const response = await axiosClientWithTenantId.get(url, { params: paramData })
  return response.data
}

export const getScheduleType = async (workspaceId: number, scheduleTypeId: number): Promise<ScheduleTypeResponse> => {
  const url = `/workspaces/${workspaceId}/schedule-types/${scheduleTypeId}`

  const response = await axiosClientWithTenantId.get(url)
  return parseResponse(response.data)
}

export const createScheduleType = async (
  workspaceId: number,
  data: ScheduleTypeEditDataType
): Promise<CreateResponse> => {
  const url = `/workspaces/${workspaceId}/schedule-types`

  const response = await axiosClientWithTenantId.post(url, convertToEditRequest(data))
  return response.data
}

export const updateScheduleType = async (
  workspaceId: number,
  scheduleTypeId: number,
  data: ScheduleTypeEditDataType
): Promise<UpdateResponse> => {
  const url = `/workspaces/${workspaceId}/schedule-types/${scheduleTypeId}`

  const response = await axiosClientWithTenantId.patch(url, convertToEditRequest(data))
  return response.data
}

export const deleteScheduleType = async (workspaceId: number, scheduleTypeId: number): Promise<DeleteResponse> => {
  const url = `/workspaces/${workspaceId}/schedule-types/${scheduleTypeId}`

  const response = await axiosClientWithTenantId.delete(url)
  return response.data
}

export const getRelatedScheduleType = async (workDate?: string): Promise<RelatedScheduleTypeListResponse> => {
  const url = '/related-schedule-types'

  const paramData = workDate ? { 'work-date': workDate } : {}
  const response = await axiosClientWithTenantId.get(url, { params: paramData })
  return response.data
}

/**
 * ScheduleTypeResponseのfilterにidを加えて画面側で扱い形式に変換する。
 */
const parseResponse = (response: OriginalScheduleTypeResponse) => {
  const filters = response.scheduleType.filters
    .filter(parent => parent.length > 0)
    .map(parent => {
      const data = parent.map(child => ({
        id: getUUID(),
        key: child.key,
        value: child.value,
      }))
      return {
        id: getUUID(),
        data,
      }
    })
  const scheduleTypeWithFilter = {
    ...response.scheduleType,
    filters,
  }

  return { ...response, scheduleType: scheduleTypeWithFilter }
}

/**
 * 画面側で加えたidを除外してAPI用の値に変換する。
 */
const convertToEditRequest = (src: ScheduleTypeEditDataType) => {
  const filters = src.filters.map(parent => parent.data.map(row => ({ key: row.key, value: row.value })))
  return { ...src, filters: filters.length > 0 ? filters : [[]] }
}
