import dayjs from 'dayjs'
import { isEqual, pick, sortBy } from 'es-toolkit'
import moment from 'moment'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { Button } from 'reactstrap'

import { COLOR_TYPES } from 'api/constants'
import { PLAN_UPDATE_MODE_TYPES } from 'api/plans/constants'
import type { UpdatePlanBulk, UpdatePlanSchedule } from 'api/plans/types'
import type { ColorType } from 'api/types'

import { getPlanByDate, getPlanList, updatePlanBulkCreate, selectPlansStatus } from 'slices/plansSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import SupportConfirm from 'components/SupportConfirm/SupportConfirm'
import {
  TimeScale,
  WorkerIcon,
  MultipleFooter,
  WorksSelector,
  WorkerPopover,
  ShiftBar,
  SubmitFooter,
} from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import type { MultipleFooterItem, EditSchedule, SelectedScheduleType } from 'components/common/types'
import { getShiftBarWidthByDuration, getUpdateWorkerSchedules } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'
import useWorkNotification from 'hooks/useWorkNotification'

import ShiftInputScale from './ShiftInputScale'
import TeamWorkPlanCard from './TeamWorkPlanCard'

import styles from './TeamWorkPlan.module.scss'

import type { ScheduleType, InputScheduleType } from './ShiftInputScale'
import type { TeamWorkPlanCardItem } from './TeamWorkPlanCard'

export type WorkPlanSchedulesType = EditSchedule & {
  editable: boolean
}

type CurrentSchedule = {
  workerId: number
  schedule: WorkPlanSchedulesType
  scheduleType: SelectedScheduleType
}

type EditWorkerSchedule = {
  workerId: number
  name: string
  schedules: WorkPlanSchedulesType[]
  selected: boolean
}

export const TeamWorkPlan = () => {
  const [viewState, setViewState] = useState(false)
  const [inputMode, setInputMode] = useState(false)
  const [selectorOpen, setSelectorOpen] = useState(false)
  const [editChangesDiscardDialogOpen, setEditChangesDiscardDialogOpen] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState<CurrentSchedule | undefined>()
  const [selectedScheduleType, setSelectedScheduleType] = useState<ScheduleType | undefined>()
  const [workers, setWorkers] = useState<EditWorkerSchedule[]>([])
  const [initWorkers, setInitWorkers] = useState<EditWorkerSchedule[]>([])
  const [inputSchedule, setInputSchedule] = useState<InputScheduleType | undefined>()
  const [openSupportConfirm, setOpenSupportConfirm] = useState(false)
  const [onApprove, setOnApprove] = useState<() => void>(() => {})

  const {
    team: { workspaceId, groupId, groupName, workerId },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { plans, planList, isRequesting, errorMessage } = useSelector(selectPlansStatus, shallowEqual)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    planWorkDate,
    planScheduleTypes,
    planLastUpdatedAt,
    planLastUpdater,
    getEditSchedulesFromWorkPlan,
    getWorkerPlanFromUpdatePlanSchedule,
  } = usePlans()
  const { setSubmitted, submitted } = useWorkNotification()

  const {
    businessStartTime,
    businessEndTime,
    businessDuration,
    getTimesByShiftBarX,
    getShiftBarXbyStartTime,
    getTimeOver24h,
  } = useBusinessTime()

  const params = useParams<'date'>()
  const date = useMemo(() => String(params.date), [params.date])

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])
  useEffect(() => {
    if (!date) {
      return
    }
    dispatch(getPlanList(workspaceId, date, date))
    dispatch(getPlanByDate(workspaceId, date))
  }, [dispatch, workspaceId, date])

  const isSupport = useMemo(() => selectedScheduleType?.color === 'secondary', [selectedScheduleType?.color])

  useEffect(() => {
    const workerGroup = plans?.groups.find(g => g.groupName === groupName)
    const newWorkers =
      workerGroup?.workersPlan
        .filter(w => w.workShifts.some(shift => shift))
        .map(w => {
          const schedules = getEditSchedulesFromWorkPlan(w, workerGroup.isSupported).map(s => ({
            ...s,
            editable: true,
          }))
          return {
            workerId: w.workerId,
            name: w.workerName,
            schedules,
            selected: false,
          }
        }) || []
    setInitWorkers(newWorkers)
    setWorkers(newWorkers)
  }, [getEditSchedulesFromWorkPlan, groupId, groupName, plans])

  const workPlanCardItems = useMemo(() => {
    if (!plans) {
      return []
    }

    const targetDailyPlan = planList?.dailyPlans.find(d => d.workDate === date)?.data
    if (!targetDailyPlan) {
      return []
    }

    return planScheduleTypes.reduce((acc: TeamWorkPlanCardItem[], cur) => {
      const target = targetDailyPlan.find(p => p.scheduleTypeId === cur.id)

      if (cur.dataConnection && target) {
        acc.push({
          label: cur.name,
          color: cur.color,
          targetValue: target.target,
          planValue: target.plan,
        })
      }
      return acc
    }, [])
  }, [plans, planList?.dailyPlans, planScheduleTypes, date])

  const title = useMemo(() => `${moment(date).format('YYYY/MM/DD（dddd）')}の作業計画`, [date])

  const inputStartAt = useMemo(() => {
    if (inputSchedule) {
      const { hours, minutes } = getTimesByShiftBarX(inputSchedule.startX)
      return dayjs(`${date} ${hours}:${minutes}`).utc().format()
    }
    return ''
  }, [getTimesByShiftBarX, date, inputSchedule])
  const inputDuration = useMemo(
    () => (inputSchedule ? (inputSchedule.endX - inputSchedule.startX + 1) * 900 : 0),
    [inputSchedule]
  )
  const start = useMemo(() => {
    if (inputMode) {
      const startTime = dayjs(inputStartAt).local().format('HH:mm')
      return getTimeOver24h(startTime, true)
    }
    return moment(currentSchedule?.schedule.startAt).format('HH:mm')
  }, [inputStartAt, currentSchedule, inputMode, getTimeOver24h])
  const end = useMemo(() => {
    if (inputMode) {
      const endTime = dayjs(inputStartAt).local().add(inputDuration, 'seconds').format('HH:mm')
      return getTimeOver24h(endTime)
    }
    return moment(currentSchedule?.schedule.startAt).add(currentSchedule?.schedule.duration, 'seconds').format('HH:mm')
  }, [inputDuration, inputStartAt, currentSchedule, inputMode, getTimeOver24h])

  const updateWorkerSchedules = useCallback(
    (
      scheduleTypeId: number,
      supportWorkspaceId: number | null,
      supportWorkspaceName: string | null,
      schedules: WorkPlanSchedulesType[]
    ) => {
      if (inputDuration <= 0) {
        return schedules
      }

      return getUpdateWorkerSchedules(
        inputStartAt,
        inputDuration,
        scheduleTypeId,
        supportWorkspaceId,
        supportWorkspaceName,
        schedules
      ).map(s => ({
        ...s,
        editable: true,
      }))
    },
    [inputStartAt, inputDuration]
  )

  const addSchedule = useCallback(() => {
    if (!selectedScheduleType) {
      return
    }

    const scheduleTypeId = isSupport ? TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT : selectedScheduleType.scheduleTypeId
    const supportWorkspaceId = isSupport ? selectedScheduleType.scheduleTypeId : null
    const supportWorkspaceName = isSupport ? selectedScheduleType.name : null

    const newWorkers = workers.map(w => {
      if (w.selected) {
        return {
          ...w,
          selected: false,
          schedules: updateWorkerSchedules(scheduleTypeId, supportWorkspaceId, supportWorkspaceName, w.schedules),
        }
      }
      return w
    })

    setWorkers(newWorkers)
    setInputMode(false)
    setSelectedScheduleType(undefined)
    setInputSchedule(undefined)
  }, [isSupport, selectedScheduleType, workers, updateWorkerSchedules])

  const deleteWorkerSchedule = useCallback(
    (targetWorkerId: number, scheduleId: number | null) => {
      const newWorkers = workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s => w.workerId !== targetWorkerId || s.scheduleId !== scheduleId),
      }))
      setWorkers(newWorkers)
    },
    [workers]
  )

  const handleOpenSupportConfirm = (func: () => void, support: boolean) => {
    setOnApprove(() => func)
    if (support) {
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const footer = useMemo((): MultipleFooterItem => {
    const disabled = workers.every(w => !w.selected) || !!selectedScheduleType
    if (selectorOpen) {
      return {
        disabled,
        stepNumber: 2,
        stepText: '作業内容を選択',
        decisionButtonClick: () => null,
      }
    }
    if (!selectorOpen && !!selectedScheduleType) {
      return {
        disabled: !inputSchedule,
        stepNumber: 3,
        stepText: '時間帯を選択',
        decisionButtonClick: () =>
          handleOpenSupportConfirm(() => {
            addSchedule()
          }, isSupport),
      }
    }
    return {
      disabled,
      stepNumber: 1,
      stepText: 'スケジュールを変更したいユーザーを選択',
      decisionButtonClick: () => setSelectorOpen(true),
    }
  }, [addSchedule, inputSchedule, selectedScheduleType, selectorOpen, workers, isSupport])

  const workerActiveRange = (schedules: WorkPlanSchedulesType[]) => {
    const sortedShifts = sortBy(schedules, ['startAt']).filter(
      s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT
    )
    if (sortedShifts.length === 0) {
      return
    }
    const range: Array<[number, number]> = sortedShifts.map(s => {
      const startX = getShiftBarXbyStartTime(s.startAt, planWorkDate)
      const endX = getShiftBarWidthByDuration(s.duration) + startX - 1
      return [startX, endX]
    })
    return range
  }

  const WorkerPopoverClickHandler = (
    targetWorkerId: number,
    schedule: WorkPlanSchedulesType,
    scheduleType: SelectedScheduleType
  ) => {
    setCurrentSchedule({ workerId: targetWorkerId, schedule, scheduleType })
    setSelectorOpen(true)
  }

  const getShiftBarItems = (schedules: WorkPlanSchedulesType[], targetWorkerId: number) => {
    return schedules
      .filter(d => d.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
      .map((d, index) => {
        const x = getShiftBarXbyStartTime(d.startAt, planWorkDate)
        const width = getShiftBarWidthByDuration(d.duration)

        const support = d.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT
        const scheduleType = support
          ? { id: d.supportWorkspaceId || 0, name: d.supportWorkspaceName || '', color: undefined }
          : pick(partialScheduleTypes.find(s => s.id === d.scheduleTypeId)!, ['id', 'name', 'color'])

        return {
          id: `item-${groupId}-${index}`,
          content: (
            <WorkerPopover
              startAt={d.startAt}
              duration={d.duration}
              scheduleType={scheduleType}
              disabled={inputMode || !d.editable}
              onClick={() => WorkerPopoverClickHandler(targetWorkerId, d, scheduleType)}
              onDelete={() => deleteWorkerSchedule(targetWorkerId, d.scheduleId)}
            >
              <div className="mh-100">{scheduleType.name}</div>
            </WorkerPopover>
          ),
          x,
          width,
          color: scheduleType?.color,
          disabled: !d.editable,
        }
      })
  }

  const selectWorker = (targetWorkerId: number) => {
    if (!inputMode || footer.stepNumber !== 1) {
      return
    }
    const newWorkers = workers.map(w => (w.workerId === targetWorkerId ? { ...w, selected: !w.selected } : w))
    setWorkers(newWorkers)
  }

  const moveToWorkerDetail = (targetWorkerId: number) => {
    if (inputMode) {
      return
    }

    navigate(`/team-worker/${targetWorkerId}`)
  }

  const cancelInputMode = () => {
    setSelectorOpen(false)
    setInputMode(false)
    setCurrentSchedule(undefined)
    setSelectedScheduleType(undefined)
    setInputSchedule(undefined)
    setWorkers(workers.map(d => ({ ...d, selected: false })))
  }

  const selectorWorkClick = (id: number, name: string, color?: ColorType) => {
    const workspace = !color ? partialWorkspaces.find(w => w.id === id) : undefined
    const scheduleTypeId = !color ? TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT : id
    const supportWorkspaceId = !color ? id : null
    const supportWorkspaceName = workspace?.name || null

    if (inputMode) {
      if (!color) {
        setSelectedScheduleType(
          workspace ? { scheduleTypeId: workspace.id, name: workspace.name, color: 'secondary' } : workspace
        )
      } else {
        const targetScheduleType = partialScheduleTypes.find(s => s.id === id)
        setSelectedScheduleType(
          targetScheduleType && {
            scheduleTypeId: targetScheduleType.id,
            name: targetScheduleType.name,
            color: targetScheduleType.color,
          }
        )
      }
    } else {
      const newWorkers = workers.map(cur => {
        if (cur.workerId === currentSchedule?.workerId) {
          return {
            ...cur,
            schedules: cur.schedules.map(s =>
              isEqual(s, currentSchedule.schedule)
                ? { ...s, scheduleTypeId, supportWorkspaceId, supportWorkspaceName }
                : s
            ),
          }
        }
        return cur
      })
      if (!color) {
        setSelectedScheduleType(
          workspace ? { scheduleTypeId: workspace.id, name: workspace.name, color: 'secondary' } : workspace
        )
      }
      handleOpenSupportConfirm(() => {
        setWorkers(newWorkers)
        setCurrentSchedule(undefined)
        setSelectedScheduleType(undefined)
      }, !color)
    }
    setSelectorOpen(false)
  }

  const shiftInputScaleClick = (targetWorkerId: number, x: number) => {
    if (!inputSchedule || inputSchedule.workerId !== targetWorkerId || inputSchedule.startX !== inputSchedule.endX) {
      setInputSchedule({ workerId: targetWorkerId, startX: x, endX: x })
    } else {
      setInputSchedule({ ...inputSchedule, endX: x })
    }
  }

  const unchanged = useMemo(() => isEqual(workers, initWorkers), [initWorkers, workers])
  const onCancel = () => {
    setWorkers(initWorkers)
    setEditChangesDiscardDialogOpen(false)
  }

  const createUpdateSchedulesData = useCallback(() => {
    const schedules = workers.flatMap(cur => {
      const initWorkersSchedules = initWorkers.find(init => init.workerId === cur.workerId)?.schedules || []

      const newSchedules: UpdatePlanSchedule[] = cur.schedules.map(s => ({
        scheduleId: null,
        schedule: {
          scheduleTypeId: s.scheduleTypeId,
          supportWorkspaceId: s.supportWorkspaceId,
          startAt: s.startAt,
          duration: s.duration,
          workerId: cur.workerId,
          groupId: null,
        },
      }))

      const deleteSchedule: UpdatePlanSchedule[] = initWorkersSchedules
        .filter(init => !cur.schedules.some(edit => edit.scheduleId === init.scheduleId))
        .map(init => ({ scheduleId: init.scheduleId, schedule: null }))

      const updateSchedules: UpdatePlanSchedule[] = cur.schedules.map(s => ({
        scheduleId: s.scheduleId,
        schedule: {
          scheduleTypeId: s.scheduleTypeId,
          supportWorkspaceId: s.supportWorkspaceId,
          startAt: s.startAt,
          duration: s.duration,
          workerId: cur.workerId,
          groupId: null,
        },
      }))

      return newSchedules.concat(deleteSchedule, updateSchedules)
    }, [])

    return getWorkerPlanFromUpdatePlanSchedule(schedules)
  }, [getWorkerPlanFromUpdatePlanSchedule, initWorkers, workers])

  useEffect(() => {
    if (!submitted || isRequesting || !date) {
      return
    }

    if (errorMessage === '') {
      dispatch(getPlanByDate(workspaceId, date))
    }
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId, date])

  const onSubmit = () => {
    if (!plans || !date) {
      return
    }
    setSubmitted(true)

    const updatePlanDate: UpdatePlanBulk = {
      updateMode: PLAN_UPDATE_MODE_TYPES.WORK_PLAN,
      workersPlan: createUpdateSchedulesData(),
      updatedByWorkerId: workerId,
    }
    dispatch(updatePlanBulkCreate(workspaceId, date, updatePlanDate))
  }

  const currentGroupColor = plans?.groups.find(g => g.groupName === groupName)?.groupColor || COLOR_TYPES.SILVER

  return (
    <>
      <div className={styles.container}>
        <Link
          to={`/team-schedules`}
          className="d-flex align-items-center text-secondary w-25 ps-4 pt-4 text-decoration-none"
        >
          <i className="icf-chevron_left" />
          <span style={{ marginTop: '0.2rem' }}>日付一覧へ</span>
        </Link>
        <div className="d-flex align-items-center px-4">
          <div className="font-x-large fw-bold m-0 flex-grow-1">{title}</div>
          <div className="bg-white rounded">
            <Button outline className="d-flex align-items-center" onClick={() => setViewState(!viewState)}>
              <i className={`icf-carot_${viewState ? `down` : `right`} pe-2 font-large`} />
              <div className="ps-1">目標と計画の比較</div>
            </Button>
          </div>
          <div className="bg-white rounded ms-2">
            <Button
              outline
              className="d-flex align-items-center"
              disabled={inputMode}
              onClick={() => setInputMode(true)}
            >
              <i className="icf-edit pe-2 font-large" />
              <div className="ps-1">作業計画の入力</div>
            </Button>
          </div>
        </div>
        <div className="my-3 px-4 d-flex text-nowrap overflow-auto">
          {viewState &&
            workPlanCardItems.map(item => (
              <TeamWorkPlanCard
                key={item.label}
                label={item.label}
                color={item.color}
                targetValue={item.targetValue}
                planValue={item.planValue}
              />
            ))}
        </div>

        <div className={styles.tableWrapper}>
          <table>
            <thead>
              <tr className="border-bottom">
                <td className={`bg-secondary-pale px-3 ${styles.tableHeader}`}>名前</td>
                <td className="p-0">
                  <TimeScale />
                </td>
              </tr>
            </thead>
            <tbody>
              {workers.map(worker => (
                <tr key={worker.workerId} className={styles.tableRow}>
                  <td
                    className={`px-3 ${worker.selected && 'bg-primary text-white'} ${styles.tableHeader}`}
                    onClick={() => selectWorker(worker.workerId)}
                  >
                    <div className="d-flex align-items-center">
                      <WorkerIcon name={worker.name} groupColor={currentGroupColor} />
                      <span className="ps-3 pe-1 flex-grow-1 text-truncate">{worker.name}</span>
                      <i
                        className={`icf-info font-large text-${worker.selected ? 'white' : 'secondary'}`}
                        onClick={() => moveToWorkerDetail(worker.workerId)}
                      />
                    </div>
                  </td>
                  <td className={styles.tableContent}>
                    <ShiftBar
                      items={getShiftBarItems(worker.schedules, worker.workerId)}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      activeRange={workerActiveRange(worker.schedules)}
                      disabled={inputMode}
                      isTeam={true}
                    />
                    <ShiftInputScale
                      show={footer.stepNumber === 3 && worker.selected}
                      businessStartTime={businessStartTime}
                      businessEndTime={businessEndTime}
                      shiftBarWidth={businessDuration}
                      inputSchedule={inputSchedule}
                      selectedScheduleType={selectedScheduleType}
                      activeRange={workerActiveRange(worker.schedules)}
                      onClick={x => shiftInputScaleClick(worker.workerId, x)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <WorksSelector
        open={selectorOpen}
        scheduleTypes={partialScheduleTypes}
        workspaces={partialWorkspaces}
        currentScheduleType={currentSchedule?.scheduleType}
        workspaceId={workspaceId}
        onClose={cancelInputMode}
        onWorkClick={selectorWorkClick}
      />
      {inputMode && (
        <MultipleFooter
          stepNumber={footer.stepNumber}
          stepText={footer.stepText}
          disabled={footer.disabled}
          selectorOpen={selectorOpen}
          decisionButtonClick={footer.decisionButtonClick}
          onCancel={cancelInputMode}
        />
      )}
      {!inputMode && !unchanged && (
        <SubmitFooter
          onCancel={() => setEditChangesDiscardDialogOpen(true)}
          onSubmit={onSubmit}
          updatedBy={planLastUpdater}
          updatedAt={planLastUpdatedAt}
        />
      )}

      <EditChangesDiscardDialog
        isOpen={editChangesDiscardDialogOpen}
        onCancel={() => setEditChangesDiscardDialogOpen(false)}
        onDiscard={onCancel}
      />

      <SupportConfirm
        isOpen={openSupportConfirm}
        start={start}
        end={end}
        name={selectedScheduleType?.name || ''}
        onCancel={() => setOpenSupportConfirm(false)}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}
