import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getAllGroups, selectGroupsStatus } from 'slices/groupsSlice'
import { showSuccess } from 'slices/notificationSlice'
import { selectOptimizationStatus, clearError, createRoster } from 'slices/optimizationSlice'
import { getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getWorkerList, selectWorkersStatus } from 'slices/workersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  apiKey: string
  magiQannealTenant: string
  magiQannealLocations: string[]
  onCancel: () => void
}

const WorkersOptEngineDialog = ({ isOpen, apiKey, magiQannealTenant, magiQannealLocations, onCancel }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string>()

  const dispatch = useDispatch()
  const { optimizationError, isRequesting } = useSelector(selectOptimizationStatus, shallowEqual)
  const { partialWorkers } = useSelector(selectWorkersStatus, shallowEqual)
  const { allGroups } = useSelector(selectGroupsStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const workspaceIds = useMemo(() => partialWorkspaces.map(w => w.id), [partialWorkspaces])

  const onSubmit = () => {
    setSubmitted(true)
    magiQannealLocations.forEach(location =>
      dispatch(
        createRoster(
          apiKey,
          magiQannealTenant,
          location,
          moment().format(),
          partialWorkspaces,
          allGroups,
          partialWorkers,
          allScheduleTypes.filter(s => workspaceIds.includes(s.workspaceId))
        )
      )
    )
  }

  useEffect(() => {
    if (!isOpen || isRequesting) {
      return
    }

    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される

      dispatch(clearError())
      onCancel()
    } else if (submitted) {
      dispatch(showSuccess())
      onCancel()
    }
    setSubmitted(false)
  }, [dispatch, isOpen, isRequesting, onCancel, optimizationError, submitted])

  useEffect(() => {
    if (isOpen) {
      dispatch(getWorkerList())
      dispatch(getRelatedScheduleType())
      dispatch(getAllGroups(workspaceIds))
      return
    }
    setModalErrorMessage(undefined)
  }, [apiKey, dispatch, isOpen, workspaceIds])

  return (
    <CustomModal
      isOpen={isOpen}
      title="magiQannealと連携"
      approveLabel="magiQannealと連携"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={onSubmit}
      approveDisabled={!apiKey}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">magiQannealとメンバーデータ（所属ワークスペース・グループ・人時生産性）を共有します。</div>
    </CustomModal>
  )
}

export default WorkersOptEngineDialog
