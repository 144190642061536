import { isEqual } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import { getBopReportsLaborCosts, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getDisplayFilter, selectUsersStatus, updateDisplayFilter } from 'slices/usersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BOP_TYPE } from 'components/Dashboard/utils'
import { BadgeLabel, Chart, GraphSelectButton, GroupRadioButton, NotSelectedPlaceholder } from 'components/common'
import { PLACE_HOLDER_TYPES } from 'components/common/NotSelectedPlaceholder/NotSelectedPlaceholder'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'

import { useBopLaborCosts } from 'hooks/useBopLaborCosts'
import { useBopReportsQuery } from 'hooks/useBopReportsQuery'

import { BopReportsCommon, toggleButtonItemList } from './BopReportsCommon'

export const BopReportLaborCosts = () => {
  const dispatch = useDispatch()

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { displayFilter, isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { bopReportsLaborCosts } = useSelector(selectBopReportsStatus, shallowEqual)

  const [submitted, setSubmitted] = useState(false)
  const [isPercentage, setIsPercentage] = useState(false)
  const [selectedBopType, setSelectedBopType] = useState(BOP_TYPE.ACTUAL)
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState<number[]>([])

  const { queryStart, queryEnd } = useBopReportsQuery()
  const { reportLaborCostsGraphOptions } = useBopLaborCosts(selectedBopType, isPercentage)

  useEffect(() => {
    dispatch(getDisplayFilter())
  }, [dispatch])

  useEffect(() => {
    dispatch(getBopReportsLaborCosts({ from: queryStart, to: queryEnd, displayFilter: true }))
  }, [dispatch, queryStart, queryEnd])

  useEffect(() => {
    // 選択されている日付が変更された時、保存されたワークスペースのフィルターを適用する
    setSelectedWorkspaceIds(
      displayFilter?.bopReport.workspaceData
        .filter(workspace => workspace.isFilteredInLaborCosts)
        .map(workspace => workspace.id) || []
    )
  }, [displayFilter, queryStart, queryEnd])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const filterItems = useMemo(
    () =>
      partialWorkspaces?.map(workspace => ({
        key: workspace.id,
        label: workspace.name,
        checked: selectedWorkspaceIds.includes(workspace.id),
      })) || [],
    [partialWorkspaces, selectedWorkspaceIds]
  )

  const handleWorkspaceSelect = useCallback(
    (items: number[]) => {
      if (isEqual(items, selectedWorkspaceIds)) {
        return
      }

      setSelectedWorkspaceIds(items)
      dispatch(getBopReportsLaborCosts({ from: queryStart, to: queryEnd, workspaceIds: items.join() }))
    },
    [dispatch, queryEnd, queryStart, selectedWorkspaceIds]
  )
  const handleWorkspaceFilterSaveButtonClick = useCallback(() => {
    if (!displayFilter) {
      return
    }

    setSubmitted(true)
    const updateWorkspaces = displayFilter.bopReport.workspaceData.map(df => {
      return { ...df, isFilteredInLaborCosts: selectedWorkspaceIds.includes(df.id) }
    })

    dispatch(updateDisplayFilter({ bopReport: { workspaceData: updateWorkspaces } }))
  }, [dispatch, displayFilter, selectedWorkspaceIds])

  return (
    <BopReportsCommon selectedWorkspaceIds={selectedWorkspaceIds}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="fw-bold font-large text-nowrap">労務費バランス</CardTitle>
            <GraphSelectButton
              items={filterItems}
              selectedGraphs={selectedWorkspaceIds}
              onChange={handleWorkspaceSelect}
              text="表示ワークスペース"
              onSaveButtonClick={handleWorkspaceFilterSaveButtonClick}
            />
          </div>
          {selectedWorkspaceIds.length === 0 ? (
            <NotSelectedPlaceholder type={PLACE_HOLDER_TYPES.WORKSPACE} />
          ) : (
            <>
              <div className="d-flex align-items-baseline my-2">
                <GroupRadioButton
                  items={toggleButtonItemList}
                  initSelectedId={toggleButtonItemList[1].id}
                  onChange={setSelectedBopType}
                />
              </div>
              <Input
                className="form-check-input me-2"
                type="checkbox"
                onChange={e => setIsPercentage(e.target.checked)}
                checked={isPercentage}
              />
              <Label className="form-check-label mb-4">割合表示</Label>
              <Chart options={reportLaborCostsGraphOptions} />
              <div className="d-flex">
                <BadgeLabel label="変動直接労務費" color="danger-stronger-middle" />
                <BadgeLabel label="変動間接労務費" color="danger-middle" />
                <BadgeLabel label="間接労務費" color="danger-pale" />
              </div>
              <UpdateLabel updatedAt={bopReportsLaborCosts?.updatedAt} />
            </>
          )}
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}
