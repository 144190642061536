import { isEqual, omit } from 'es-toolkit'
import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Row, Col } from 'reactstrap'

import type { UpdateWorkerEditDataType } from 'api/workers/types'

import { getGroup, selectGroupsStatus } from 'slices/groupsSlice'
import { selectNotificationStatus, showSuccess, showError, notificationClear } from 'slices/notificationSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { getWorker, selectWorkersStatus, updateWorker } from 'slices/workersSlice'
import { selectWorkspacesStatus, getWorkspaceList } from 'slices/workspacesSlice'

import { Notification, VerticalTable } from 'components/common'
import type { VerticalItem } from 'components/common/types'

import useWorker from 'hooks/useWorker'

import HourlyProductivitiesInput from './PerformanceIndicesInput'

import styles from './WorkerDetail.module.scss'

const WorkerDetail = () => {
  const params = useParams<'workerId'>()
  const workerId = Number(params.workerId)
  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { worker, isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { group } = useSelector(selectGroupsStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const {
    showSuccess: showSuccessDisplay,
    showError: showErrorDisplay,
    showWarning,
    errorMessage: errorMessageFromStore,
    successMessage,
    warningMessage,
  } = useSelector(selectNotificationStatus, shallowEqual)

  const { showHourlyProductivities, disabled, editData, setEditData, setNameValidity, setHourlyProductivities } =
    useWorker()

  const [forecastColorScheduleTypeIds, setForecastColorScheduleTypeIds] = useState<number[]>([])

  useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(notificationClear())
  }, [dispatch])

  useEffect(() => {
    dispatch(getWorker(workerId))
  }, [dispatch, workerId])

  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const workspace = useMemo(
    () => partialWorkspaces.find(w => w.id === worker?.workspaceId),
    [partialWorkspaces, worker?.workspaceId]
  )

  useEffect(() => {
    if (!worker || !worker?.workspaceId || !worker?.groupId) {
      return
    }
    dispatch(getGroup(worker.workspaceId, worker.groupId))
  }, [dispatch, worker])

  useEffect(() => {
    if (!worker) {
      return
    }

    setNameValidity(true)
    setEditData(prev => ({
      ...prev,
      hourlyProductivities: worker.hourlyProductivities,
    }))
  }, [worker, setNameValidity, allScheduleTypes, setEditData])

  const unchanged = useMemo(
    () => isEqual(editData.hourlyProductivities, worker?.hourlyProductivities),
    [editData.hourlyProductivities, worker?.hourlyProductivities]
  )
  const workerInfoList: VerticalItem[] = useMemo(
    () => [
      { title: '名前', data: worker?.name },
      { title: '所属', data: workspace?.name },
      { title: '所属グループ', data: worker?.groupId ? group?.name : '未所属' },
      { title: '役割', data: worker?.groupLeader ? 'グループリーダー' : '-' },
    ],
    [group?.name, worker?.groupId, worker?.groupLeader, worker?.name, workspace?.name]
  )

  const onSubmit = () => {
    if (!worker) {
      return
    }

    // 人時生産性設定
    if (!isEqual(editData.hourlyProductivities, worker.hourlyProductivities)) {
      setSubmitted(true)
      const data: UpdateWorkerEditDataType = {
        name: worker.name,
        hourlyProductivities: editData.hourlyProductivities.map(productivity => omit(productivity, ['average'])),
      }
      dispatch(updateWorker(worker.id, data))
    }
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      setForecastColorScheduleTypeIds([])
    } else {
      // ENABLE_DIALOGのときにはエラーダイアログが出るのでNotificationは出さない
      if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">作業者詳細</div>
          <Notification
            success={showSuccessDisplay}
            error={showErrorDisplay}
            warning={showWarning}
            errorMessage={errorMessageFromStore}
            successMessage={successMessage}
            warningMessage={warningMessage}
            hide={() => {
              dispatch(notificationClear())
            }}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-3">
          <div className="font-middle fw-bold py-1 mt-3">作業者詳細</div>
          <div className="my-2 border-top border-end border-start">
            <VerticalTable items={workerInfoList} titleColumn={3} />
          </div>
          {showHourlyProductivities && (
            <div>
              <div className="font-middle fw-bold pt-3">人時生産性設定</div>
              <div className="py-2">
                全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
              </div>
              <HourlyProductivitiesInput
                workerId={workerId}
                forecastColorScheduleTypeIds={forecastColorScheduleTypeIds}
                onFocus={setForecastColorScheduleTypeIds}
                hourlyProductivities={editData.hourlyProductivities}
                onChange={setHourlyProductivities}
              />
            </div>
          )}
        </div>
      </div>
      <div className="bg-white border-top position-sticky fixed-bottom">
        <div className="d-flex justify-content-between mx-4 py-3">
          <div className="flex-grow-1">
            <Button outline onClick={() => navigate(-1)}>
              キャンセル
            </Button>
          </div>
          <Row xs="1" className="font-x-small text-muted text-end">
            <Col>最終編集：{worker?.updatedByName}</Col>
            <Col>{moment(worker?.updatedAt).format('YYYY年MM月DD日 HH:mm')}</Col>
          </Row>
          <Button color="primary" className="px-4 ms-3" disabled={unchanged || disabled} onClick={onSubmit}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default WorkerDetail
