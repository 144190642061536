import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import { getReportProductivity, selectReportsStatus } from 'slices/reportsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { NULL_GROUP_NAME, SUPPORT_WORKER_GROUP_ID } from 'components/Dashboard/utils'
import { Pivot, PivotItem } from 'components/common'
import { PeriodSelect } from 'components/common/PeriodSelect/PeriodSelect'
import { isSupportedWorkerGroup } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import ProductivityGroupGraph from './ProductivityGroupGraph'
import ProductivityWorkerGraph, { TAB_TYPE } from './ProductivityWorkerGraph'

import type { GraphProductivityWorker } from './ProductivityWorkerGraph'

const NO_WORKSPACE_ID = 0

type Props = {
  workspaceId?: number
}

const ReportProductivity = ({ workspaceId }: Props) => {
  const { getPeriod } = useBusinessTime()
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(getPeriod)

  const dispatch = useDispatch()
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  useEffect(() => {
    if (!tenant) {
      return
    }
    setPeriod(getPeriod())
  }, [tenant, getPeriod])

  useEffect(() => {
    if (!workspaceId || !tenant) {
      return
    }
    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    const from = moment(period.start).format('YYYY-MM-DD')
    const to = moment(period.end).format('YYYY-MM-DD')
    dispatch(getReportProductivity(workspaceId, from, to))
  }, [dispatch, workspaceId, period, tenant])
  const { productivity } = useSelector(selectReportsStatus, shallowEqual)

  const currentWorkers = useMemo(() => {
    if (!productivity) {
      return []
    }
    return productivity.groups
      .filter(group => !isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName))
      .flatMap(group =>
        group.workers.map<GraphProductivityWorker>(worker => ({
          ...worker,
          workspaceId: productivity.workspaceId,
          workspaceName: productivity.workspaceName,
          groupId: group.groupId ?? 0,
          groupName: group.groupName || NULL_GROUP_NAME,
        }))
      )
  }, [productivity])

  const otherWorkers = useMemo(() => {
    if (!productivity) {
      return []
    }
    return productivity.groups
      .filter(group => isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName))
      .flatMap(group =>
        group.workers.map<GraphProductivityWorker>(worker => ({
          ...worker,
          workspaceId: group.supportedWorkspaceId ?? NO_WORKSPACE_ID,
          workspaceName: group.supportedWorkspaceName ?? '',
          groupId: SUPPORT_WORKER_GROUP_ID,
          groupName: '',
        }))
      )
  }, [productivity])

  return (
    <Card className="mb-3">
      <CardBody className="d-flex justify-content-between align-items-center">
        <div className="fw-bold">生産性の推移</div>
        <PeriodSelect period={period} onChange={(start, end) => setPeriod({ start, end })} />
      </CardBody>

      <CardBody className="py-0">
        <Pivot>
          {[
            { header: 'ワークスペース/グループ', key: 'group' },
            { header: 'メンバー', key: TAB_TYPE.CURRENT },
            { header: '他のワークスペースのメンバー', key: TAB_TYPE.OTHER },
          ].map(({ header, key }) => (
            <PivotItem headerText={header} key={key}>
              {key === 'group' ? (
                <ProductivityGroupGraph productivity={productivity} start={period.start} end={period.end} />
              ) : key === TAB_TYPE.CURRENT ? (
                <ProductivityWorkerGraph
                  graphProductivityWorkers={currentWorkers}
                  start={period.start}
                  end={period.end}
                  type={key}
                />
              ) : key === TAB_TYPE.OTHER ? (
                <ProductivityWorkerGraph
                  graphProductivityWorkers={otherWorkers}
                  start={period.start}
                  end={period.end}
                  type={key}
                />
              ) : null}
            </PivotItem>
          ))}
        </Pivot>
      </CardBody>
    </Card>
  )
}

export default ReportProductivity
