import { isEqual } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Button } from 'reactstrap'

import { PLAN_UPDATE_MODE_TYPES } from 'api/plans/constants'
import type { WorkersPlan, UpdatePlanBulk } from 'api/plans/types'
import { ROLE } from 'api/users/constants'

import { showError } from 'slices/notificationSlice'
import { selectPlansStatus, updatePlanBulkCreate } from 'slices/plansSlice'
import { getScheduleTypeList } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { selectWorkspacesStatus, getWorkspace } from 'slices/workspacesSlice'

import {
  NavMenu,
  BadgeLabel,
  SubmitFooter,
  MoveDropdown,
  CustomButton,
  TimeSelect,
  SidebarButton,
} from 'components/common'
import { TIME_INTERVAL } from 'components/common/constants'

import useAssignment from 'hooks/useAssignment'
import useAuthority from 'hooks/useAuthority'
import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'
import useWorkspaceSideBar from 'hooks/useWorkspaceSideBar'

import AssignmentWorkerFilter from './AssignmentWorkerFilter'
import DroppingWorkerCards, { PREFIX_WORKSPACE_ID } from './DroppingWorkerCards'

import styles from './Assignment.module.scss'

import type { ScheduleEditType } from './DroppingWorkerCards'
import type { DropdownScheduleType } from './WorkerCard'

const Assignment = () => {
  const [scheduleEditData, setScheduleEditData] = useState<ScheduleEditType[]>([])
  const [isOpenSidebar, setOpenSidebar] = useState(true)
  const [shouldResetFilters, setShouldResetFilters] = useState(false)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { partialWorkspaces, workspace } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { plans } = useSelector(selectPlansStatus, shallowEqual)

  const { startTime, openDayjs, workspaceId, setSubmitted, getSubmitWorkerPlanFromWorker, initSchedules } =
    useAssignment()
  const { planWorkDate, planLastUpdatedAt, planLastUpdater } = usePlans()

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  const { timeRange, getHourOver24h } = useBusinessTime({ interval: TIME_INTERVAL.FIVE })

  const { workspaceName } = useWorkspaceSideBar('/assignment')

  const disabled = useMemo(
    () => user.userHasTenants[0].role === ROLE.PROCESS_ADMIN && workspace && !workspace.memberIds.includes(user.userId),
    [user, workspace]
  )

  const otherWorkspaces: DropdownScheduleType[] = useMemo(
    (): DropdownScheduleType[] =>
      partialWorkspaces
        .filter(w => w.id !== workspaceId)
        .map(w => ({ type: [PREFIX_WORKSPACE_ID, w.id].join('-'), label: w.name })),
    [workspaceId, partialWorkspaces]
  )

  useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getScheduleTypeList(workspaceId)) // useAssignmentで利用する
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  const onCancel = () => {
    setScheduleEditData(initSchedules)
    setShouldResetFilters(true)
  }
  const onSubmit = () => {
    if (!plans || !workspaceId) {
      dispatch(showError())
      return
    }
    setShouldResetFilters(true)
    setSubmitted(true)
    const compare = initSchedules.flatMap(s => s.workers)
    const data: WorkersPlan[] = scheduleEditData
      .filter(d => d.workers.length > 0)
      .flatMap(d => {
        const type = d.type.split('-')
        const isSupport = type[0] === PREFIX_WORKSPACE_ID
        return d.workers
          .filter(
            worker =>
              !isEqual(
                compare.find(item => item.workerId === worker.workerId),
                worker
              )
          )
          .flatMap(worker => getSubmitWorkerPlanFromWorker(worker, type[1], isSupport))
      })

    const updatePlanDate: UpdatePlanBulk = {
      updateMode: PLAN_UPDATE_MODE_TYPES.WORK_PLAN,
      workersPlan: data,
    }

    dispatch(updatePlanBulkCreate(workspaceId, planWorkDate, updatePlanDate))
  }

  const unchanged = useMemo(() => isEqual(initSchedules, scheduleEditData), [initSchedules, scheduleEditData])

  useEffect(() => setScheduleEditData(initSchedules), [initSchedules])

  return (
    <>
      <NavMenu isOpenSidebar={isOpenSidebar}>
        <>
          <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-3 pt-3">
              <div className="d-flex">
                <div className="font-x-large fw-bold">人員配置</div>
                <div className="px-2 align-self-center">
                  <BadgeLabel label={workspaceName} />
                </div>
                <SidebarButton isOpenSidebar={isOpenSidebar} onClick={() => setOpenSidebar(!isOpenSidebar)} />
              </div>
              <div className="d-flex">
                {!!workspaceId && (
                  <>
                    <MoveDropdown />
                    <CustomButton
                      icon="edit"
                      outline
                      disabled={isReadOnlyWorkspace}
                      className="ms-2"
                      onClick={() => navigate(`/workspaces/${workspaceId}`)}
                    >
                      ワークスペース設定
                    </CustomButton>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center pb-3">
              <div className="pe-2">表示中の配置:</div>
              <TimeSelect
                hour={getHourOver24h(startTime)}
                minute={startTime.split(':')[1]}
                label=""
                onChange={(hour, minute) => navigate(`?hour=${hour}&minute=${minute}`, { replace: true })}
                range={timeRange.start}
                roundingInterval={TIME_INTERVAL.FIVE}
              />
              <div className="pe-2">からの配置</div>
              <Button
                color="link"
                className="shadow-none text-decoration-none"
                onClick={() => navigate(pathname, { replace: true })}
              >
                現在時刻の配置にする
              </Button>
            </div>

            <AssignmentWorkerFilter
              initSchedules={initSchedules}
              onChange={setScheduleEditData}
              setShouldReset={setShouldResetFilters}
              shouldReset={shouldResetFilters}
            />
            <Row>
              <DroppingWorkerCards
                scheduleEditData={scheduleEditData}
                otherWorkspaces={otherWorkspaces}
                currentTime={openDayjs.format('YYYY/MM/DD HH:mm')}
                isOpenSidebar={isOpenSidebar}
                setScheduleEditData={setScheduleEditData}
              />
            </Row>
          </div>
          <SubmitFooter
            onCancel={onCancel}
            onSubmit={onSubmit}
            cancelDisabled={unchanged}
            submitDisabled={unchanged || disabled}
            updatedBy={planLastUpdater}
            updatedAt={planLastUpdatedAt}
            submitName="assignment-submit"
          />
        </>
      </NavMenu>
    </>
  )
}

export default Assignment
