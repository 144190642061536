import type { CreateResponse, UpdateResponse } from 'api/types'

import { axiosClient, axiosClientWithTenantId } from 'AxiosClientProvider'

import { DEFAULT_LANGUAGE, LOCALE } from './constants'

import type {
  DataConnectionInfoResponse,
  TenantApplicationSettingType,
  TenantEditDataType,
  TenantListResponse,
  TenantResponse,
} from './types'

export const getTenantList = async (): Promise<TenantListResponse> => {
  const url = '/tenants'

  const response = await axiosClient.get(url)
  return response.data
}

export const createTenant = async (data: TenantEditDataType): Promise<CreateResponse> => {
  const url = '/tenants'

  const response = await axiosClient.post(url, convertToEditRequest(data))
  return response.data
}

export const getTenant = async (tenantId: number, date?: string): Promise<TenantResponse> => {
  const url = `/tenants/${tenantId}`

  const paramData = date ? { 'work-date': date } : {}
  const response = await axiosClient.get(url, { params: paramData })
  return response.data
}

export const updateTenant = async (tenantId: number, data: TenantEditDataType): Promise<UpdateResponse> => {
  const url = `/tenants/${tenantId}`

  const response = await axiosClient.patch(url, convertToEditRequest(data))
  return response.data
}

export const getDataConnectionInfo = async (): Promise<DataConnectionInfoResponse> => {
  const url = '/data-connection-info'

  const response = await axiosClientWithTenantId.get(url)
  return response.data
}

export const updateTenantStatus = async (tenantId: number, isActive: boolean): Promise<UpdateResponse> => {
  const url = `/tenants/${tenantId}/status`

  const response = await axiosClient.patch(url, { isActive })
  return response.data
}

export const updateTenantApplication = async (
  tenantId: number,
  optionApplications: TenantApplicationSettingType[]
): Promise<UpdateResponse> => {
  const url = `/tenants/${tenantId}/application`

  const response = await axiosClient.patch(url, { optionApplications })
  return response.data
}

/**
 * 画面編集用の営業時間をAPIの形式に変換する。
 */

const convertToEditRequest = (src: TenantEditDataType) => {
  const { businessStartHour, businessStartMinute, businessEndHour, businessEndMinute, ...data } = src
  return {
    ...data,
    businessStartTime: businessStartHour + ':' + businessStartMinute,
    businessEndTime: businessEndHour + ':' + businessEndMinute,
    locale: LOCALE,
    defaultLanguage: DEFAULT_LANGUAGE,
  }
}
