import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { sortBy } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { showSuccess } from 'slices/notificationSlice'
import {
  selectOptimizationStatus,
  getLocationConfig,
  getMagiQannealSchedule,
  clearError,
} from 'slices/optimizationSlice'

import { CustomModal } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import { getRandomNumber } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import type { EditGroupsType, WorkPlanSchedulesType } from '../types'

dayjs.extend(isBetween)

type ScheduleType = {
  scheduleTypeId: number
  startAt: string
  duration: number
}
type EditOptEngineScheduleType = {
  wmsMemberId: string
  schedules: ScheduleType[]
}

type Props = {
  isOpen: boolean
  apiKey: string
  magiQannealTenant: string
  magiQannealLocation: string
  editGroups: EditGroupsType[]
  setEditGroups: (items: EditGroupsType[]) => void
  onCancel: () => void
  workDate?: string
}

const INTERVAL_MINUTES_UNIT = 15
const SLOTS_LENGTH_IN_A_DAY = 24 * 4

const createWorkerSchedules = (optSchedules: ScheduleType[], workerSchedules: WorkPlanSchedulesType[]) => {
  const formatted = optSchedules.map<WorkPlanSchedulesType>(data => ({
    scheduleId: getRandomNumber(),
    scheduleTypeId: data.scheduleTypeId,
    supportWorkspaceId: null,
    supportWorkspaceName: null,
    startAt: data.startAt,
    duration: data.duration,
    editable: true,
  }))

  const newWorkerSchedules = workerSchedules.reduce<WorkPlanSchedulesType[]>((acc, cur) => {
    // シフト情報と応援のデータの場合はそのまま返す
    if (
      cur.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT ||
      (cur.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT && !cur.editable)
    ) {
      acc.push(cur)
      return acc
    }

    const reschedule = optSchedules.reduce<{ startAt?: string; duration?: number }>(
      (worker, opt) => {
        if (!worker.startAt || !worker.duration) {
          return worker
        }
        const optStart = dayjs(opt.startAt)
        const optEnd = dayjs(opt.startAt).add(opt.duration, 'seconds')
        const workerEnd = dayjs(worker.startAt).add(worker.duration, 'seconds')
        // optEngineのレスポンススケジュールのstartAtが登録スケジュールに含まれる時
        // startAt以降の登録スケジュールは削除される（上書き）
        if (optStart.isSame(worker.startAt, 'minute')) {
          return {}
        }
        if (optStart.isBetween(worker.startAt, workerEnd, 'minute', '()')) {
          return { ...worker, duration: worker.duration - (workerEnd.unix() - optStart.unix()) }
        }

        // optEngineのレスポンススケジュールのEND値が登録スケジュールに含まれる時
        // END値以前の登録スケジュールは削除される（上書き）
        if (optEnd.isSame(workerEnd, 'minute')) {
          return {}
        }
        if (optEnd.isBetween(worker.startAt, workerEnd, 'minute', '()')) {
          return {
            startAt: optEnd.format(),
            duration: workerEnd.unix() - optEnd.unix(),
          }
        }

        // optEngineのレスポンススケジュールに登録スケジュールが含まれる時
        if (
          dayjs(worker.startAt).isBetween(optStart, optEnd, 'minute', '()') &&
          workerEnd.isBetween(optStart, optEnd, 'minute', '()')
        ) {
          return {}
        }
        return worker
      },
      { startAt: cur.startAt, duration: cur.duration }
    )

    if (reschedule.startAt && reschedule.duration) {
      acc.push({ ...cur, startAt: reschedule.startAt, duration: reschedule.duration })
    }

    return acc
  }, [])

  return newWorkerSchedules.concat(formatted)
}

// シフト情報と応援情報から入力可能域を'1'にして返す
const getInputableSchedules = (schedules: WorkPlanSchedulesType[], workDate: string, startIndex: number) => {
  const supportAndShiftSchedules = schedules.filter(s =>
    [TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT, TENTATIVE_SCHEDULE_TYPE_ID.SHIFT].includes(s.scheduleTypeId)
  )
  return sortBy(supportAndShiftSchedules, ['scheduleTypeId']).reduce(
    (acc, cur) => {
      const isSupport = cur.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SUPPORT
      const isShift = cur.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT
      // 編集可能な応援は対象外
      if (isSupport && cur.editable) {
        return acc
      }

      const baseDate = dayjs(workDate).startOf('day')
      const start = dayjs(cur.startAt)
      const end = dayjs(cur.startAt).add(cur.duration, 'seconds')
      return [...Array(SLOTS_LENGTH_IN_A_DAY)]
        .map((_, index) => {
          const addHour = startIndex > index ? 24 : 0
          const isActiveTime = baseDate
            .add(INTERVAL_MINUTES_UNIT * (index + 1), 'minutes')
            .add(addHour, 'hour')
            .isBetween(start, end, 'minutes', '(]')
          return isActiveTime && isShift ? '1' : isActiveTime && isSupport ? '0' : acc.charAt(index)
        })
        .join('')
    },
    ''.padStart(SLOTS_LENGTH_IN_A_DAY, '0')
  )
}

const ImportOptimizedDialog = (props: Props) => {
  const { apiKey, magiQannealTenant, magiQannealLocation, isOpen, editGroups, setEditGroups, onCancel, workDate } =
    props
  const [submitted, setSubmitted] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string>()
  const { isRequesting, optimizationError, optimizedSchedule, data } = useSelector(
    selectOptimizationStatus,
    shallowEqual
  )
  const dispatch = useDispatch()
  const { businessStartTime, businessStartIndex } = useBusinessTime()

  const handleSubmitClick = () => {
    const date = dayjs(workDate).startOf('day').format()
    dispatch(getMagiQannealSchedule(apiKey, magiQannealTenant, magiQannealLocation, date))
    setSubmitted(true)
  }

  const optimizedScheduleIds = useMemo(() => data?.works.map(w => w.workId) ?? [], [data?.works])

  const formattedWorkerSchedules = useMemo(
    () =>
      editGroups.flatMap(edit =>
        edit.workers.map(w => ({
          ...w,
          schedules: getInputableSchedules(w.schedules, dayjs(workDate).format('YYYY-MM-DD'), businessStartIndex),
        }))
      ),
    [businessStartIndex, editGroups, workDate]
  )

  const editOptEngineSchedules = useMemo(() => {
    const startTime = businessStartTime.split(':')
    // 営業開始時間を設定する
    const date = dayjs(optimizedSchedule?.period.datetimeFrom)
      .startOf('day')
      .hour(Number(startTime[0]))
      .minute(Number(startTime[1]))

    const changeScheduleOrder = (schedule: string) => {
      // 営業開始時間の位置で順序を入れ替える
      // 営業開始時間に該当する値がIndex0となるようにする
      const before24h = schedule.substring(businessStartIndex, SLOTS_LENGTH_IN_A_DAY)
      const after24h = schedule.substring(0, businessStartIndex)

      return before24h + after24h
    }

    // optEngineのレスポンスデータを startAt/duration 型に変更する
    // 応援のデータが入力済みの箇所を調整
    return optimizedSchedule?.workerSchedules.map<EditOptEngineScheduleType>(worker => {
      const targetInput = formattedWorkerSchedules.find(w => w.wmsMemberId === worker.workerId)?.schedules
      const inputable = targetInput && changeScheduleOrder(targetInput)
      const schedules = worker.assignedSchedule.flatMap<ScheduleType>(s => {
        const targetSchedule = changeScheduleOrder(s.schedule)
        const workData: [dayjs.Dayjs, number][] = []
        for (let index = 0; index < SLOTS_LENGTH_IN_A_DAY; index++) {
          if (targetSchedule.charAt(index) === '1' && (!inputable || inputable.charAt(index) === '1')) {
            const start = date.clone().add(index * INTERVAL_MINUTES_UNIT, 'minutes')
            const startIndex = index

            while (
              index + 1 < SLOTS_LENGTH_IN_A_DAY &&
              targetSchedule.charAt(index + 1) === '1' &&
              inputable?.charAt(index + 1) === '1'
            ) {
              index++
            }
            const endIndex = index
            const duration = (endIndex - startIndex + 1) * INTERVAL_MINUTES_UNIT * 60

            workData.push([start, duration])
          }
        }

        return workData.map(d => ({ scheduleTypeId: s.workId, startAt: d[0].utc().format(), duration: d[1] }))
      })
      return { wmsMemberId: worker.workerId, schedules }
    })
  }, [
    businessStartTime,
    optimizedSchedule?.period.datetimeFrom,
    optimizedSchedule?.workerSchedules,
    businessStartIndex,
    formattedWorkerSchedules,
  ])

  const formattedEditGroups = useMemo(
    () =>
      editGroups.map<EditGroupsType>(edit => {
        const workers = edit.workers.map(worker => {
          const found = editOptEngineSchedules?.find(opt => opt.wmsMemberId === worker.wmsMemberId)
          const filtered = worker.schedules.filter(s => !optimizedScheduleIds.includes(s.scheduleTypeId))
          if (found) {
            const schedules = createWorkerSchedules(found.schedules, filtered)
            return { ...worker, schedules }
          }
          return worker
        })

        return { ...edit, workers }
      }),
    [editGroups, editOptEngineSchedules, optimizedScheduleIds]
  )

  useEffect(() => {
    if (!isOpen || isRequesting) {
      return
    }
    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される

      dispatch(clearError())
      onCancel()
    } else if (submitted && !optimizationError) {
      setEditGroups(formattedEditGroups)
      onCancel()
      dispatch(
        showSuccess(
          optimizedSchedule?.workerSchedules.length === 0
            ? { successMessage: '「最適配置」のデータがありません。' }
            : undefined
        )
      )
    }
    setSubmitted(false)
  }, [
    dispatch,
    formattedEditGroups,
    isOpen,
    isRequesting,
    onCancel,
    optimizationError,
    setEditGroups,
    submitted,
    optimizedSchedule?.workerSchedules,
  ])

  useEffect(() => {
    if (isOpen) {
      // 最適配置対象作業を取得する
      dispatch(
        getLocationConfig(apiKey, magiQannealTenant, magiQannealLocation, dayjs(workDate).startOf('day').format())
      )
    }
  }, [apiKey, dispatch, isOpen, workDate, magiQannealTenant, magiQannealLocation])

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    onCancel()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="配置結果取込み"
      approveLabel="取込"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSubmitClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div>「最適配置」の結果を作業計画に取込みます。</div>
      <div>（「最適配置ボタン」押下以降に入力された作業計画は上書きされます）</div>
    </CustomModal>
  )
}

export default ImportOptimizedDialog
