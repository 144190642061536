import moment from 'moment'
import { useState, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, Tooltip } from 'reactstrap'

import { COLOR_TYPES } from 'api/constants'

import { getReportAverage, selectReportsStatus } from 'slices/reportsSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { BadgeLabel } from 'components/common'
import { PeriodSelect } from 'components/common/PeriodSelect/PeriodSelect'

import useBusinessTime from 'hooks/useBusinessTime'

const productReport = (value: number | null): string => {
  return value !== null ? (Math.floor(value * 10) / 10).toFixed(1).toLocaleString() : '-'
}
const accuracyCount = (productivity: number | null, accuracy: number | null, hours: number): string => {
  if (!productivity || !accuracy) {
    return '-'
  }
  return Math.floor((productivity * accuracy * hours) / 100).toLocaleString()
}

type Props = {
  workspaceId?: number
}

const ReportAverage = ({ workspaceId }: Props) => {
  const { businessDuration, getPeriod } = useBusinessTime()
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(getPeriod)

  // 1日の営業時間 tenantHours を求める

  const dispatch = useDispatch()
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  useEffect(() => {
    if (!tenant) {
      return
    }
    setPeriod(getPeriod())
  }, [tenant, getPeriod])

  useEffect(() => {
    if (!workspaceId || !tenant) {
      return
    }
    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    const from = moment(period.start).format('YYYY-MM-DD')
    const to = moment(period.end).format('YYYY-MM-DD')
    dispatch(getReportAverage(workspaceId, from, to))
  }, [dispatch, workspaceId, period, tenant])

  const { reportAverages } = useSelector(selectReportsStatus, shallowEqual)

  const TooltipHintIcon = ({ text }: { text: string }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const toggle = () => setTooltipOpen(!tooltipOpen)
    const ref = useRef(null)

    return (
      <div>
        <i className="icf-hint font-middle" ref={ref} />
        <Tooltip target={ref} toggle={toggle} isOpen={tooltipOpen} placement="bottom">
          {text}
        </Tooltip>
      </div>
    )
  }

  return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="fw-bold">指定期間のサマリー</div>
          <PeriodSelect period={period} onChange={(start, end) => setPeriod({ start, end })} />
        </div>

        <Row md={3} className="g-3">
          {reportAverages.map(reportAverage => (
            <Col key={`report-card-col-${reportAverage.scheduleTypeId}`}>
              <Card>
                <CardBody className="d-flex">
                  <BadgeLabel label={reportAverage.scheduleTypeName} color={reportAverage.scheduleTypeColor} />
                </CardBody>
                <CardBody className="pt-0">
                  <div className="font-x-small text-muted d-flex">
                    生産性
                    <TooltipHintIcon text="指定された期間の実績の合計を作業計画で入力された予定の合計で割った数値" />
                  </div>

                  <span className="font-large fw-bold">
                    {reportAverage.hourlyAvgProductivity !== null
                      ? Math.floor(reportAverage.hourlyAvgProductivity).toLocaleString()
                      : '-'}
                    {reportAverage.unit}
                  </span>
                  <small> /1時間</small>
                </CardBody>
                <hr className="m-0" color={COLOR_TYPES.SILVER} />
                <CardBody>
                  <div className="font-x-small text-muted d-flex">
                    平均計画変更率
                    <TooltipHintIcon text="アーカイブされた作業計画と最終的な作業計画の変更割合（=計画変更率）の指定された期間の平均" />
                  </div>
                  <span className="font-large fw-bold">&#177;{productReport(reportAverage.dailyAvgPlanAccuracy)}%</span>
                  <small>/1日</small>
                  <div className="font-x-small text-muted">
                    &#177;
                    {accuracyCount(
                      reportAverage.hourlyAvgProductivity,
                      reportAverage.dailyAvgPlanAccuracy,
                      businessDuration / 4
                    )}
                    {reportAverage.unit}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default ReportAverage
