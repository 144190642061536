import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import * as API from 'api/groups/groups'
import type { GroupData, GroupListResponse, GroupResponse } from 'api/groups/types'
import type { ColorType } from 'api/types'

import { handleApiError } from './utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type AllGroupType = {
  groups: GroupData[]
  workspaceId: number
}

type GroupsState = {
  isRequesting: boolean
  errorMessage: string
  group?: GroupData
  groups: GroupData[]
  allGroups: AllGroupType[]
  groupWorkspaceId: number | undefined
}

const initialState: GroupsState = {
  isRequesting: false,
  errorMessage: '',
  group: undefined,
  groups: [],
  allGroups: [],
  groupWorkspaceId: undefined,
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getGroupListSuccess: (state, action: PayloadAction<GroupListResponse>) => {
      state.isRequesting = false
      state.groups = sortBy(action.payload.workGroups, ['name'])
      state.groupWorkspaceId = action.payload.partialWorkspace.id
    },
    getGroupSuccess: (state, action: PayloadAction<GroupResponse>) => {
      state.isRequesting = false
      state.group = action.payload.workGroup
    },
    createGroupSuccess: state => {
      state.isRequesting = false
    },
    updateGroupSuccess: state => {
      state.isRequesting = false
    },
    deleteGroupSuccess: state => {
      state.isRequesting = false
    },
    getAllGroupsSuccess: (state, action: PayloadAction<AllGroupType[]>) => {
      state.isRequesting = false
      state.allGroups = action.payload
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getGroupListSuccess,
  getGroupSuccess,
  createGroupSuccess,
  updateGroupSuccess,
  deleteGroupSuccess,
  getAllGroupsSuccess,
} = groupsSlice.actions

export const getGroupList =
  (workspaceId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getGroupList(workspaceId)
      dispatch(getGroupListSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getGroup =
  (workspaceId: number, groupId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getGroup(workspaceId, groupId)
      dispatch(getGroupSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const createGroup =
  (workspaceId: number, name: string, color: ColorType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.createGroup(workspaceId, name, color)
      dispatch(createGroupSuccess())
      dispatch(getGroupList(workspaceId))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const updateGroup =
  (workspaceId: number, groupId: number, name: string, color: ColorType): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateGroup(workspaceId, groupId, name, color)
      dispatch(updateGroupSuccess())
      dispatch(getGroupList(workspaceId))
      dispatch(getGroup(workspaceId, groupId))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const deleteGroup =
  (workspaceId: number, groupId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.deleteGroup(workspaceId, groupId)
      dispatch(deleteGroupSuccess())
      dispatch(getGroupList(workspaceId))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const getAllGroups =
  (workspaceIds: number[]): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const promises = workspaceIds.map(async workspaceId => {
        const res = await API.getGroupList(workspaceId)
        return {
          groups: res.workGroups,
          workspaceId,
        }
      })

      const res = await Promise.all(promises)
      dispatch(getAllGroupsSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const selectGroupsStatus = (state: RootState) => ({ ...state.groups })

export default groupsSlice.reducer
