import moment from 'moment'
import { useState, useEffect, useMemo, Fragment } from 'react'
import { DropdownItem, Button } from 'reactstrap'

import { PERIOD_LIST } from 'components/Reports/utils'
import type { PeriodListType } from 'components/Reports/utils'
import { SingleDateRangePicker } from 'components/common/DatePicker/SingleDateRangePicker'
import { DropdownList } from 'components/common/DropdownList/DropdownList'

import useBusinessTime from 'hooks/useBusinessTime'

// DatePickerに設定可能な最大日数
const DATE_PICKER_MAX_DAYS = 90

export type Props = {
  period: { start: Date; end: Date }
  periodListData?: PeriodListType[]
  minDate?: Date
  maxDate?: Date
  onChange: (start: Date, end: Date) => void
}

const PeriodSelect = ({ period, periodListData, minDate, maxDate, onChange }: Props) => {
  const [openPeriod, setOpenPeriod] = useState(false)
  const [periodLabel, setPeriodLabel] = useState('')
  const [openRangeDatePicker, setOpenRangeDatePicker] = useState(false)
  const { getWorkDate } = useBusinessTime()

  const DefaultPeriodList = useMemo(() => {
    const today = getWorkDate(moment().format('YYYY-MM-DD'))

    return [
      {
        label: '過去7日間',
        periodStart: moment(today).subtract(PERIOD_LIST.DAYS_7, 'days').toDate(),
        periodEnd: moment(today).subtract(1, 'days').toDate(),
        id: '7days',
      },
      {
        label: '過去15日間',
        periodStart: moment(today).subtract(PERIOD_LIST.DAYS_15, 'days').toDate(),
        periodEnd: moment(today).subtract(1, 'days').toDate(),
        id: '15days',
      },
      {
        label: '過去30日間',
        periodStart: moment(today).subtract(PERIOD_LIST.DAYS_30, 'days').toDate(),
        periodEnd: moment(today).subtract(1, 'days').toDate(),
        id: '30days',
      },
      {
        label: '過去45日間',
        periodStart: moment(today).subtract(PERIOD_LIST.DAYS_45, 'days').toDate(),
        periodEnd: moment(today).subtract(1, 'days').toDate(),
        id: '45days',
      },
      {
        label: '過去90日間',
        periodStart: moment(today).subtract(PERIOD_LIST.DAYS_90, 'days').toDate(),
        periodEnd: moment(today).subtract(1, 'days').toDate(),
        id: '90days',
      },
      {
        label: '開始日と終了日を設定',
        periodStart: moment(today).toDate(),
        periodEnd: moment(today).toDate(),
        id: 'user_setting',
      },
    ]
  }, [getWorkDate])

  const periodList = useMemo(() => periodListData || DefaultPeriodList, [DefaultPeriodList, periodListData])

  useEffect(() => {
    const start = moment(period.start)
    const end = moment(period.end)

    const label =
      periodList.find(
        p =>
          moment(p.periodStart).format('YYYY/MM/DD') === start.format('YYYY/MM/DD') &&
          moment(p.periodEnd).format('YYYY/MM/DD') === end.format('YYYY/MM/DD')
      )?.label || `${start.format('YYYY/MM/DD')} - ${end.format('YYYY/MM/DD')}`

    setPeriodLabel(label)
  }, [period, periodList])

  const onPeriodClick = (selectedId: string) => {
    if (selectedId === 'user_setting') {
      // 開始日と終了日を設定する場合はperiod選択状態を変更せず、date pickerを表示する
      setOpenRangeDatePicker(true)
      return
    }

    const selectedPeriodData = periodList.find(p => p.id === selectedId)
    const start = selectedPeriodData!.periodStart
    const end = selectedPeriodData!.periodEnd
    onChange(start, end)
  }

  const onRangeDatePickerChange = (from: Date, to: Date) => {
    setOpenRangeDatePicker(false)

    if (from && to) {
      onChange(from, to)
    }
  }

  const toggleContent = (
    <div className="bg-white rounded">
      <Button outline className="d-flex align-items-center">
        <div>{periodLabel}</div>
        <i className="icf-carot_down ps-2 font-large" />
      </Button>
    </div>
  )

  return (
    <div>
      <DropdownList
        open={openPeriod}
        setOpen={() => setOpenPeriod(!openPeriod)}
        content={toggleContent}
        direction="start"
      >
        {periodList.map((m, index) => (
          <Fragment key={m.id}>
            <DropdownItem onClick={() => onPeriodClick(m.id)}>{m.label}</DropdownItem>
            {index === periodList.length - 2 && <DropdownItem divider />}
          </Fragment>
        ))}
      </DropdownList>

      <SingleDateRangePicker
        isOpen={openRangeDatePicker}
        from={period.start}
        to={period.end}
        maxRange={DATE_PICKER_MAX_DAYS}
        minDate={
          minDate ||
          moment(getWorkDate(moment().format('YYYY-MM-DD')))
            .subtract(DATE_PICKER_MAX_DAYS, 'days')
            .toDate()
        }
        maxDate={
          maxDate ||
          moment(getWorkDate(moment().format('YYYY-MM-DD')))
            .subtract(1, 'days')
            .toDate()
        }
        onCancel={() => setOpenRangeDatePicker(false)}
        onChange={onRangeDatePickerChange}
      />
    </div>
  )
}

export { PeriodSelect, PERIOD_LIST }
