import { useState, useEffect, useMemo, useCallback } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import type { HourlyProductivities, WorkerEditType } from 'api/workers/types'

import { getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { WORKER_TYPES_LABEL } from 'components/common/constants'

const initData: WorkerEditType = {
  id: undefined,
  name: undefined,
  wmsMemberId: undefined,
  officialDuties: undefined,
  workspace: undefined,
  groupId: undefined,
  groupLeader: false,
  hiredAt: undefined,
  skills: [],
  hourlyProductivities: [],
  workerTypes: WORKER_TYPES_LABEL[0],
}
const useWorker = () => {
  const [editData, setEditData] = useState<WorkerEditType>(initData)
  const [nameValidity, setNameValidity] = useState(false)
  const dispatch = useDispatch()

  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  useEffect(() => {
    dispatch(getRelatedScheduleType())
  }, [dispatch])

  const setHourlyProductivities = useCallback(
    (value: HourlyProductivities[]) => setEditData(prev => ({ ...prev, hourlyProductivities: value })),
    []
  )

  const showHourlyProductivities = useMemo(() => allScheduleTypes.length > 0, [allScheduleTypes])

  const disabled = useMemo(() => !nameValidity, [nameValidity])

  return {
    setHourlyProductivities,
    showHourlyProductivities,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    initData,
  }
}
export default useWorker
