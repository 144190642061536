import dayjs from 'dayjs'
import { merge, round } from 'es-toolkit'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import type { ColorType } from 'api/types'

import { colorTypeToCode, createXAxis, formatPositiveNumber, getStyledColorClass } from 'components/Dashboard/utils'
import { BadgeLabel, Chart, BadgeButton } from 'components/common'
import { createLineChartOptions } from 'components/common/Chart/Chart'
import type { Series } from 'components/common/types'

import useBusinessTime from 'hooks/useBusinessTime'

import ScheduleTypeSelector from './ScheduleTypeSelector'

import styles from './WorkPlanCard.module.scss'

import type { ScheduleTypeGroup } from './ScheduleTypeSelector'
import type * as Highcharts from 'highcharts'

const createYAxis = (
  xAxisData: string[],
  graphRows: GraphRow[],
  color: ColorType,
  unit: string | null,
  name: string,
  opacity: number
): Series[] => {
  const colorCode = colorTypeToCode(color)

  const recordData = xAxisData.map(time => {
    // パフォーマンス改善のために moment でなく new Date を使う
    // row.time も time も UTC なので文字列として比較しても大丈夫そうだが、安全のため new Date を通す
    const target = graphRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
    return target?.actualValue === undefined ? null : target.actualValue
  })
  const planData = xAxisData.map(time => {
    // パフォーマンス改善のために moment でなく new Date を使う
    const target = graphRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
    return target?.planValue === undefined ? null : target.planValue
  })
  return [
    {
      type: 'area',
      color: colorCode,
      name,
      opacity,
      data: recordData,
      custom: {
        unit,
      },
    },
    {
      type: 'line',
      color: colorCode,
      name,
      opacity,
      data: planData,
      custom: {
        unit,
      },
    },
  ]
}

export type GraphRow = {
  time: string
  planValue: number | null
  actualValue: number | null
}

export type CompareGraph = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string | null
  workspaceName: string
  graphRows: GraphRow[]
}

type Props = {
  scheduleTypeId: number
  label: string
  color: ColorType
  unit: string | null
  targetValue: number
  planValue: number
  date: string
  businessStartTime: string
  businessEndTime: string
  graphRows: GraphRow[]
  scheduleTypeGroups: ScheduleTypeGroup[]
  compareGraphs: CompareGraph[]
  defaultProductivity: number
  onOpenWorkPlanCard: (scheduleTypeId: number) => void
  onSelectCompareScheduleTypeId: (scheduleTypeId: number) => void
}
const WorkPlanCard = (props: Props) => {
  const {
    scheduleTypeId,
    label,
    color,
    unit,
    targetValue,
    planValue,
    date,
    businessStartTime,
    businessEndTime,
    graphRows,
    scheduleTypeGroups,
    compareGraphs,
    defaultProductivity,
    onOpenWorkPlanCard,
    onSelectCompareScheduleTypeId,
  } = props

  const { pathname } = useLocation()
  const difference = Math.floor(planValue - targetValue)
  const manHour = round(difference / defaultProductivity, 1)
  const [open, setOpen] = useState(false)
  const [compareScheduleTypeId, setCompareScheduleTypeId] = useState<number | undefined>(undefined)
  const { getTimeOver24h } = useBusinessTime()
  const rate = useMemo(
    () => (targetValue ? Math.floor((planValue / targetValue) * 100) : Math.floor(planValue) ? 100 : 0),
    [planValue, targetValue]
  )
  const barStyle = useMemo(
    () => ({
      width: `${rate}%`,
      backgroundColor: rate < 100 ? '#e33840' : '#97c5e9',
    }),
    [rate]
  )

  const badgeItems = useMemo(() => {
    const compareGraph = compareGraphs.find(g => g.scheduleTypeId === compareScheduleTypeId)
    if (!compareGraph) {
      return []
    }
    return [
      {
        color: compareGraph.scheduleTypeColor,
        key: compareGraph.scheduleTypeId,
        label: `${compareGraph.workspaceName}/${compareGraph.scheduleTypeName}`,
        disabled: true,
      },
    ]
  }, [compareScheduleTypeId, compareGraphs])

  const chartOptions = useMemo(() => {
    const xAxisData = createXAxis(businessStartTime, businessEndTime, false, date)

    const yAxis: Series[] = createYAxis(xAxisData, graphRows, color, unit, label, 1)

    const compareGraph = compareGraphs.find(g => g.scheduleTypeId === compareScheduleTypeId)
    const compareLabel = compareGraph ? `${compareGraph.workspaceName}/${compareGraph.scheduleTypeName}` : ''
    const compareYAxis = compareGraph
      ? createYAxis(
          xAxisData,
          compareGraph.graphRows,
          compareGraph.scheduleTypeColor,
          compareGraph.unit,
          compareLabel,
          0.5
        )
      : []

    const options = createLineChartOptions(
      {
        xAxis: {
          data: xAxisData,
        },
        yAxis: yAxis.concat(compareYAxis),
      },
      'Dash'
    )
    merge<Highcharts.Options, Highcharts.Options>(options, {
      chart: {
        height: 200,
      },
      tooltip: {
        formatter() {
          return (
            '<div style="text-align:center">' +
            [
              `${getTimeOver24h(dayjs(this.x).format('HH:mm'), true)}~${getTimeOver24h(dayjs(this.x).add(1, 'h').format('HH:mm'))}`,
              this.series.name,
              `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`,
            ].join('<br>') +
            '</div>'
          )
        },
      },
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 3,
      },
      yAxis: {
        labels: {
          formatter() {
            if (+this.value >= 1000) {
              return (+this.value / 1000).toLocaleString() + 'K'
            }
            return this.value.toLocaleString()
          },
        },
        tickAmount: 4,
      },
    })
    ;(options.xAxis as Highcharts.XAxisOptions).labels!.formatter = function () {
      return getTimeOver24h(dayjs(this.value).format('HH:mm'), this.isFirst)
    }
    return options
  }, [
    businessStartTime,
    businessEndTime,
    date,
    graphRows,
    color,
    unit,
    label,
    compareGraphs,
    compareScheduleTypeId,
    getTimeOver24h,
  ])

  const onOpen = useCallback(() => {
    setOpen(!open)
    onOpenWorkPlanCard(scheduleTypeId)
  }, [onOpenWorkPlanCard, open, scheduleTypeId])

  const onCompareSelectScheduleType = useCallback(
    (selectedScheduleTypeId: number) => {
      setCompareScheduleTypeId(selectedScheduleTypeId)
      onSelectCompareScheduleTypeId(selectedScheduleTypeId)
    },
    [onSelectCompareScheduleTypeId]
  )

  useEffect(() => {
    setOpen(false)
    setCompareScheduleTypeId(undefined)
  }, [pathname])

  return (
    <Card>
      <CardBody className="d-flex pb-0">
        <BadgeLabel label={label} color={color} />
      </CardBody>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <small className="text-muted">予定からの予測</small>
            <div className="font-middle fw-bold">{Math.floor(planValue).toLocaleString()}</div>
          </div>
          <div>
            <small className="text-muted">目標の</small>
            <div className="text-danger">{rate}%</div>
          </div>
          <div>
            <small className="text-muted">目標</small>
            <div>{targetValue.toLocaleString()}</div>
          </div>
        </div>
        <div className={styles.barContainer}>
          <div className="h-100" style={barStyle}></div>
        </div>
        <div className="d-flex justify-content-center text-muted mt-2">
          <span>差分&nbsp;</span>
          <span className={getStyledColorClass(difference)}>
            {formatPositiveNumber(difference)} ({formatPositiveNumber(manHour)}人時)
          </span>
        </div>
      </CardBody>
      <hr className="m-0"></hr>
      {open && (
        <>
          <div className="p-2">
            <Chart options={chartOptions} />
          </div>
          <div className="d-flex justify-content-end pt-2 px-4">
            {badgeItems.length > 0 ? (
              <div className={styles.badgeButtonContainer}>
                <BadgeButton items={badgeItems} />
              </div>
            ) : (
              <BadgeLabel label="-" className="ps-5" />
            )}
            <div className="ms-3">
              <ScheduleTypeSelector
                groups={scheduleTypeGroups}
                onSelect={item => onCompareSelectScheduleType(item.scheduleTypeId)}
              />
            </div>
          </div>
          <hr className="mb-0"></hr>
        </>
      )}
      <CardBody
        className="py-1 d-flex justify-content-center align-items-center"
        name="work-plan-card"
        onClick={() => onOpen()}
      >
        計画の推移グラフを{open ? '閉じる' : '開く'}
        <i className={`icf-carot_${open ? 'up' : 'down'} ps-1 font-large`} />
      </CardBody>
    </Card>
  )
}
export default WorkPlanCard
