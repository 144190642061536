import axios from 'axios'

import type {
  CreateAttendanceRequestType,
  CreateRosterRequestType,
  CreateTargetValuesRequestType,
  GetLocationConfigResponse,
  MagiQannealScheduleResponse,
} from './types'

export const getLocationConfig = async (
  apiKey: string,
  tenant: string,
  location: string,
  datetime: string
): Promise<GetLocationConfigResponse> => {
  const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/configs`
  const headers = { Authorization: `Bearer ${apiKey}` }

  const response = await axios.get(url, { headers, params: { datetime } })
  return response.data
}

export const createRoster = async (
  apiKey: string,
  tenant: string,
  location: string,
  request: CreateRosterRequestType
): Promise<void> => {
  const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/rosters`
  const headers = { Authorization: `Bearer ${apiKey}` }

  const response = await axios.post(url, request, { headers })
  return response.data
}

export const getMagiQannealSchedule = async (
  apiKey: string,
  tenant: string,
  location: string,
  date: string
): Promise<MagiQannealScheduleResponse> => {
  const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/schedule`
  const headers = { Authorization: `Bearer ${apiKey}` }

  const response = await axios.get(url, { headers, params: { date } })
  return response.data
}

export const getLocation = async (apiKey: string, tenant: string, location: string): Promise<void> => {
  const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}`
  const headers = { Authorization: `Bearer ${apiKey}` }

  await axios.get(url, { headers })
}

export const createTargetValues = async (
  apiKey: string,
  tenant: string,
  location: string,
  request: CreateTargetValuesRequestType
): Promise<void> => {
  const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/tvalues`
  const headers = { Authorization: `Bearer ${apiKey}` }

  const response = await axios.post(url, request, { headers })
  return response.data
}

export const createAttendance = async (
  apiKey: string,
  tenant: string,
  location: string,
  request: CreateAttendanceRequestType
): Promise<void> => {
  const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/attendances`
  const headers = { Authorization: `Bearer ${apiKey}` }

  const response = await axios.post(url, request, { headers })
  return response.data
}
