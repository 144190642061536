import dayjs from 'dayjs'
import { sortBy } from 'es-toolkit'
import { encode } from 'iconv-lite'
import { useMemo, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { CONNECTION_TYPES } from 'api/schedule_types/constants'

import { showError } from 'slices/notificationSlice'
import { selectPlansStatus } from 'slices/plansSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { exportData } from 'components/common/utils'

const useTargetsImport = () => {
  const { workspaceId } = useParams<'workspaceId'>()
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { planList } = useSelector(selectPlansStatus, shallowEqual)
  const dispatch = useDispatch()

  const header = useMemo(
    () => [
      '日付',
      ...partialScheduleTypes.filter(s => s.connectionType !== CONNECTION_TYPES.NONE).map(s => `${s.name}[${s.id}]`),
    ],
    [partialScheduleTypes]
  )

  const exportToCSV = useCallback(
    (body: string[][], fileName: string) => {
      const csvData = [header, ...body].map(r => r.join(',')).join('\n')
      const csvDataShiftJIS = encode(csvData, 'Shift_JIS')
      const blob = new Blob([csvDataShiftJIS], { type: 'text/csv' })
      exportData(blob, fileName)
    },
    [header]
  )

  const handleDataFormat = useCallback(() => {
    exportToCSV([], '作業目標データフォーマット.csv')
  }, [exportToCSV])

  const formatDate = useCallback((date: string) => dayjs(date, 'YYYY-MM-DD').format('YYYY/MM/DD'), [])

  const exportTargets = useCallback(
    (date?: string) => {
      if (!planList || !partialScheduleTypes) {
        dispatch(showError())
        return
      }

      const targetScheduleTypeIds = partialScheduleTypes
        .filter(s => s.connectionType !== CONNECTION_TYPES.NONE)
        .map(s => s.id)
      const rows = sortBy(planList.dailyPlans, [o => new Date(o.workDate).getTime()])
        .filter(p => p.workerCount > 0 && (!date || p.workDate === date))
        .map(p => ({ ...p, data: p.data.filter(d => targetScheduleTypeIds.includes(d.scheduleTypeId)) }))
        .map(p => [formatDate(p.workDate), ...sortBy(p.data, ['scheduleTypeName']).map(d => d.target.toString())])

      exportToCSV(rows, `target-${workspaceId}-${dayjs().format('YYYY-MM-DD-HHmm')}.csv`)
    },
    [dispatch, exportToCSV, formatDate, partialScheduleTypes, planList, workspaceId]
  )

  return { handleDataFormat, exportTargets, formatDate }
}
export default useTargetsImport
