import dayjs from 'dayjs'
import { merge, sortBy, sum, uniqBy } from 'es-toolkit'
import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import type { DailyPlanAccuracyRow } from 'api/reports/types'

import { selectReportsStatus, getReportPlanAccuracy } from 'slices/reportsSlice'
import { getTenantWithDate, selectTenantsStatus } from 'slices/tenantsSlice'

import { colorTypeToCode } from 'components/Dashboard/utils'
import { BadgeButton, Chart } from 'components/common'
import { PeriodSelect } from 'components/common/PeriodSelect/PeriodSelect'
import type { Series } from 'components/common/types'
import { createLineChartOptions } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import { createXAxis } from './utils'

type PlanAccuracyRatesRow = {
  date: string
  rates: number[]
}

type PropsType = {
  workspaceId?: number
}

const ReportPlanAccuracy = ({ workspaceId }: PropsType) => {
  const { getPeriod } = useBusinessTime()
  const [period, setPeriod] = useState<{ start: Date; end: Date }>(getPeriod)

  const [selectedBadgeKeys, setSelectedBadgeKeys] = useState<number[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  useEffect(() => {
    if (!tenant) {
      return
    }
    setPeriod(getPeriod())
  }, [tenant, getPeriod])

  useEffect(() => {
    if (!workspaceId || !tenant) {
      return
    }
    // getWorkDateでbusinessStartTimeを使用するためtenant取得後にAPIを実行する
    const from = moment(period.start).format('YYYY-MM-DD')
    const to = moment(period.end).format('YYYY-MM-DD')
    dispatch(getReportPlanAccuracy(workspaceId, from, to))
  }, [dispatch, workspaceId, period, tenant])
  const { dailyPlanAccuracies } = useSelector(selectReportsStatus, shallowEqual)

  const badgeItems = useMemo(() => {
    const dailyPlanAccuracyItems = dailyPlanAccuracies.map(planAccuracy => ({
      color: planAccuracy.scheduleTypeColor,
      key: planAccuracy.scheduleTypeId,
      label: planAccuracy.scheduleTypeName,
    }))
    return sortBy(
      uniqBy(dailyPlanAccuracyItems, item => item.key),
      ['key']
    )
  }, [dailyPlanAccuracies])

  useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const formatter: Highcharts.TooltipFormatterCallbackFunction = function () {
    return `${moment(this.x).format('YYYY/MM/DD')}<br>±${this.y}%`
  }

  const chartOptions = useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))
    const xAxisData = createXAxis(period.start, period.end)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const dailyRows = dailyPlanAccuracies
        .filter(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)
        .flatMap(planAccuracy =>
          planAccuracy.data.map<DailyPlanAccuracyRow>(row => ({
            workDate: dayjs(row.workDate).format('YYYY-MM-DD'),
            value: row.value,
          }))
        )

      // 最終計画か、アーカイブのどちらかが0だった場合、当てはまる時間の割合を100%にする
      // 各時間の差の割合の平均を出す
      const rates = dailyRows
        .reduce<PlanAccuracyRatesRow[]>((acc, cur) => {
          // 誤差を減らすため、rateでは切り捨てしない
          const rate = cur.value || 0

          const target = acc.find(row => row.date === cur.workDate)
          if (!target) {
            return acc.concat([{ date: cur.workDate, rates: [rate] }])
          }
          target.rates.push(rate)
          return acc
        }, [])
        // rateは小数点一桁まで出す
        .map(accuracy => ({
          date: accuracy.date,
          rate: Math.floor((sum(accuracy.rates) / accuracy.rates.length) * 10) / 10,
        }))

      // 最終計画、アーカイブのどちらもデータがない日は0とする
      const data = xAxisData.map(date => {
        const target = rates.find(rate => date === rate.date)
        if (!target) {
          return 0
        }
        return target.rate
      })

      yAxis.push({
        type: 'area',
        color: colorCode,
        data,
        name: graphBadge.label,
      })
    })

    const options = createLineChartOptions(
      {
        xAxis: {
          data: xAxisData,
        },
        yAxis,
      },
      'Dash',
      'day'
    )
    merge(options, {
      xAxis: {
        tickInterval: Math.ceil(xAxisData.length / 7),
      },
      tooltip: { formatter },
      plotOptions: {
        series: {
          cursor: 'pointer',
          events: {
            click(e: Highcharts.SeriesClickEventObject) {
              const date = moment(e.point.category).format('YYYY-MM-DD')
              navigate(`/reports/${workspaceId}/${date}`)
              dispatch(getTenantWithDate(date))
            },
          },
        },
      },
    })
    return options
  }, [badgeItems, period.start, period.end, selectedBadgeKeys, dailyPlanAccuracies, navigate, workspaceId, dispatch])

  return (
    <Card className="mb-3">
      <CardBody className="d-flex justify-content-between align-items-center">
        <div className="fw-bold">計画変更率の推移</div>
        <PeriodSelect period={period} onChange={(start, end) => setPeriod({ start, end })} />
      </CardBody>

      <CardBody>
        <Chart options={chartOptions} />
      </CardBody>
      <CardBody className="d-flex row my-2">
        <BadgeButton
          items={badgeItems}
          selected={selectedBadgeKeys}
          onChange={selected => setSelectedBadgeKeys(selected)}
        />
      </CardBody>
    </Card>
  )
}

export default ReportPlanAccuracy
