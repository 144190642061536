import { Chart, BadgeLabel } from 'components/common'

import type { Options } from 'highcharts'

type Props = {
  chartOptions?: Options
}

export const BopMonitoringChartTotal = ({ chartOptions }: Props) => {
  if (!chartOptions) {
    return null
  }

  return (
    <>
      <Chart options={chartOptions} />
      <div className="d-flex">
        <BadgeLabel label="売上" color="primary" />
        <BadgeLabel label="その他売上" color="primary-middle" />
        <BadgeLabel label="売上原価" color="danger-stronger-middle" />
        <BadgeLabel label="固定費" color="danger-middle" />
        <BadgeLabel label="その他費用" color="danger-pale" />
      </div>
    </>
  )
}
