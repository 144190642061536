import { SPOT_WORKER_SHIFT_STATUS } from 'api/spot_workers/constants'

export const UNREGISTERED_VALUE_ID = -1
export const UNSELECTED_TIME_LABEL = '--:--'
export const UNSELECTED_ITEM_LABEL = '-'
export const UNREGISTERED_GROUP_LABEL = '未所属'
export const UNREGISTERED_TEMPLATE_LABEL = '設定なし'
export const MAX_WORKING_HOURS_COL = 3
const ACTION_UPDATE = '更新'
const ACTION_DELETE = '削除'
const ACTION_REGISTER = '登録'
const ACTION_SICKOUT = '欠勤'

export const shiftStatusList = [
  {
    id: SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT,
    label: '情報不足',
    color: 'text-danger',
  },
  {
    id: SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT_WITHOUT_ID,
    label: '情報不足',
    color: 'text-danger',
  },
  {
    id: SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED,
    label: 'シフト未登録',
    color: 'text-danger',
  },
  {
    id: SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED,
    label: 'シフト登録済',
    color: 'text-success',
  },
  {
    id: SPOT_WORKER_SHIFT_STATUS.NON_UPDATED,
    label: '未更新',
    color: 'text-warning',
  },
  {
    id: SPOT_WORKER_SHIFT_STATUS.SICK_OUT,
    label: '欠勤',
    color: 'text-muted opacity-50',
  },
]

const ACTION = {
  REGISTER: 1,
  UPDATE: 2,
  DELETE: 3,
  SICKOUT: 4,
} as const

export type ActionType = (typeof ACTION)[keyof typeof ACTION]
export const Actions: Record<keyof typeof ACTION, ActionType> = {
  REGISTER: 1,
  UPDATE: 2,
  DELETE: 3,
  SICKOUT: 4,
}

export const actionList = [
  {
    status: SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED,
    actions: [
      { key: Actions.REGISTER, value: ACTION_REGISTER },
      { key: Actions.DELETE, value: ACTION_DELETE },
    ],
  },
  {
    status: SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED,
    actions: [
      { key: Actions.DELETE, value: ACTION_DELETE },
      { key: Actions.SICKOUT, value: ACTION_SICKOUT },
    ],
  },
  {
    status: SPOT_WORKER_SHIFT_STATUS.NON_UPDATED,
    actions: [
      { key: Actions.UPDATE, value: ACTION_UPDATE },
      { key: Actions.DELETE, value: ACTION_DELETE },
      { key: Actions.SICKOUT, value: ACTION_SICKOUT },
    ],
  },
  {
    status: SPOT_WORKER_SHIFT_STATUS.SICK_OUT,
    actions: [{ key: Actions.DELETE, value: ACTION_DELETE }],
  },
  {
    status: SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT,
    actions: [{ key: Actions.DELETE, value: ACTION_DELETE }],
  },
  {
    status: SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT_WITHOUT_ID,
    actions: [{ key: Actions.DELETE, value: ACTION_DELETE }],
  },
]

export const FILTER_STATUS_LABEL = {
  UNREGISTERED_WMS_MEMBER_ID: 0,
  REGISTERED_WMS_MEMBER_ID: 1,
  NEW: 2,
  SHIFT_UNREGISTERED: SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED,
  SHIFT_REGISTERED: SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED,
  NON_UPDATED: SPOT_WORKER_SHIFT_STATUS.NON_UPDATED,
} as const

export type FilterStatusType = (typeof FILTER_STATUS_LABEL)[keyof typeof FILTER_STATUS_LABEL]

export const filterStatusList = [
  { key: FILTER_STATUS_LABEL.UNREGISTERED_WMS_MEMBER_ID, label: '識別子未設定' },
  { key: FILTER_STATUS_LABEL.REGISTERED_WMS_MEMBER_ID, label: '識別子設定済' },
  { key: FILTER_STATUS_LABEL.NEW, label: '新規' },
  {
    key: FILTER_STATUS_LABEL.SHIFT_REGISTERED,
    label: shiftStatusList.find(status => status.id === SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED)?.label || '',
  },
  {
    key: FILTER_STATUS_LABEL.SHIFT_UNREGISTERED,
    label: shiftStatusList.find(status => status.id === SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED)?.label || '',
  },
  {
    key: FILTER_STATUS_LABEL.NON_UPDATED,
    label: shiftStatusList.find(status => status.id === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED)?.label || '',
  },
]

const TimeToMinutes = (time: string | null) => {
  if (time === null) {
    return 0
  }
  const hour = parseInt(time.split(':')[0])
  const minute = parseInt(time.split(':')[1])
  return hour * 60 + minute
}

export const isValidTimeSelection = (workStart: string | null, workEnd: string | null) => {
  if (typeof workStart !== typeof workEnd) {
    return false
  }
  if (workStart === null && workEnd === null) {
    return true
  }

  const startMinutes = TimeToMinutes(workStart)
  const endMinutes = TimeToMinutes(workEnd)
  return startMinutes < endMinutes
}

export const isValidTimeContinuity = (workEnd1: string | null, workStart2: string | null) => {
  if (workEnd1 === null || workStart2 === null) {
    return true
  }
  const end1Minutes = TimeToMinutes(workEnd1)
  const start2Minutes = TimeToMinutes(workStart2)
  return end1Minutes <= start2Minutes
}
