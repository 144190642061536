import dayjs from 'dayjs'
import { fill, last } from 'es-toolkit'
import { useEffect, useMemo, useCallback } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CardBody, CardTitle, Label, Button } from 'reactstrap'

import { COLOR_TYPES } from 'api/constants'
import type { PartialScheduleTypeData } from 'api/schedule_types/types'
import type { EditTemplateProps } from 'api/template/types'

import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { WorkPlanPopover } from 'components/Schedules/WorkPlan/WorkPlanPopover'
import { InputFormat, TimeScale, ShiftBar } from 'components/common'
import { COLUMN_SIZES, TENTATIVE_SCHEDULE_TYPE_ID, TIME_INTERVAL } from 'components/common/constants'

import useBusinessTime from 'hooks/useBusinessTime'

type Props = {
  editData: EditTemplateProps
  onChange: <K extends keyof EditTemplateProps>(key: K, value: EditTemplateProps[K]) => void
  onValidate: (value: boolean) => void
  onDeleteButtonClick: () => void
  disabledDeleteButton: boolean
}

export const TemplateDetailView = ({
  editData,
  onChange,
  onValidate,
  onDeleteButtonClick,
  disabledDeleteButton,
}: Props) => {
  const params = useParams<'workspaceId'>()
  const workspaceId = useMemo(() => Number(params.workspaceId), [params.workspaceId])

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const dispatch = useDispatch()

  const { businessStartTime, businessDuration, getShiftBarXbyStartTime, businessStartIndex } = useBusinessTime({
    interval: TIME_INTERVAL.FIVE,
  })

  useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const templateNameValidation = useCallback((value: string | undefined): string => {
    if (typeof value === 'undefined') {
      return ''
    }
    if (value.length === 0 || value.length > 20) {
      return '1文字以上､20文字以下で入力してください'
    }
    return ''
  }, [])

  const updateEditTemplate = useCallback(
    (id: number | null, start: number, end: number, isSupport?: boolean) => {
      onChange('ids', fill(editData.ids?.slice(), id === null ? null : { id, isSupport: !!isSupport }, start, end))
    },
    [editData.ids, onChange]
  )

  const handleDeleteSchedule = useCallback(
    (startAt: number, duration: number) => updateEditTemplate(null, startAt, startAt + duration),
    [updateEditTemplate]
  )

  const handleSelectScheduleType = useCallback(
    (id: number, startAt: number, duration: number, isSupport: boolean) => {
      updateEditTemplate(id, startAt, startAt + duration, isSupport)
    },
    [updateEditTemplate]
  )

  const templateSchedules = useMemo(() => {
    return (
      editData.ids
        ?.reduce<{ count: number; id: number | null; startIndex: number; isSupport: boolean }[]>((acc, cur, index) => {
          const prev = last(acc)
          if (!prev || prev.id !== cur?.id || prev.isSupport !== cur.isSupport) {
            return acc.concat({ id: cur?.id ?? null, count: 1, startIndex: index, isSupport: !!cur?.isSupport })
          }
          acc.splice(acc.length - 1, 1, { ...prev, count: prev.count + 1 })
          return acc
        }, [])
        .filter(d => d.id !== null) || []
    )
  }, [editData])

  const handleShiftBarAdd = useCallback(
    (startPos: number, endPos: number) => {
      const startPos5min = startPos - (startPos % 3)
      const endPos5min = endPos > startPos5min + 3 ? endPos : startPos5min + 3

      updateEditTemplate(TENTATIVE_SCHEDULE_TYPE_ID.UNSELECTED, startPos5min, endPos5min)
    },
    [updateEditTemplate]
  )

  const handleShiftBarChange = useCallback(
    (index: number, x: number, width: number) => {
      const target = templateSchedules.find((_val, i) => i === index)
      if (!target) {
        return
      }
      const idsFilledNull = fill(editData.ids?.slice(), null, target.startIndex, target.startIndex + target.count)
      const idsFilledTargetIds = fill(idsFilledNull, { id: target.id!, isSupport: target.isSupport }, x, x + width)
      onChange('ids', idsFilledTargetIds)
    },
    [templateSchedules, editData.ids, onChange]
  )

  const handleTimeChange = useCallback(
    (
      index: number,
      changeStartHour: string,
      changeStartMinute: string,
      changeEndHour: string,
      changeEndMinute: string
    ) => {
      const startDate = dayjs(`${changeStartHour}${changeStartMinute}`, 'HHmm')
      const startX = getShiftBarXbyStartTime(startDate.toISOString(), dayjs().format('YYYY-MM-DD'))
      const endDate = dayjs(`${changeEndHour}${changeEndMinute}`, 'HHmm')
      const endX = getShiftBarXbyStartTime(endDate.toISOString(), dayjs().format('YYYY-MM-DD'))

      handleShiftBarChange(index, startX, endX - startX)
    },
    [getShiftBarXbyStartTime, handleShiftBarChange]
  )
  const shiftBarItems = useMemo(() => {
    return templateSchedules.map((val, index) => {
      const target = (val.isSupport ? partialWorkspaces : partialScheduleTypes).find(st => st.id === val.id)
      const selected = {
        id: val.id!,
        name: target?.name ?? '',
        color: val.isSupport ? COLOR_TYPES.SILVER : (target as PartialScheduleTypeData)?.color ?? COLOR_TYPES.SILVER,
      }
      const startTime = dayjs()
        .startOf('day')
        .add((val.startIndex + businessStartIndex) * TIME_INTERVAL.FIVE, 'minutes')
        .format()
      const x = getShiftBarXbyStartTime(startTime, dayjs().format('YYYY-MM-DD')) // 第２引数には現在日時を使用する

      return {
        id: `item-${index}`,
        content: (
          <WorkPlanPopover
            workspaceId={workspaceId}
            scheduleId={val.id!}
            selected={selected}
            viewWorkspace={true}
            startTime={startTime}
            duration={val.count * 300}
            disabled={false}
            onSelect={item => {
              const isSupport = !item?.color
              handleSelectScheduleType(item.id, x, val.count, isSupport)
            }}
            onDelete={() => handleDeleteSchedule(x, val.count)}
            isGroup={false}
            onTimeChange={(startHour, startMinute, endHour, endMinute) =>
              handleTimeChange(index, startHour, startMinute, endHour, endMinute)
            }
            key={`popover-${editData.id}-${index}`}
          />
        ),
        x,
        width: val.count,
        color: (target as PartialScheduleTypeData)?.color ?? COLOR_TYPES.SILVER,
        disabled: false,
      }
    })
  }, [
    templateSchedules,
    partialWorkspaces,
    partialScheduleTypes,
    businessStartIndex,
    getShiftBarXbyStartTime,
    workspaceId,
    editData,
    handleSelectScheduleType,
    handleDeleteSchedule,
    handleTimeChange,
  ])

  return (
    <div className="h-100 overflow-auto">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="font-large fw-bold">予定テンプレート情報</CardTitle>
          <span className="font-x-small text-muted">※必須項目</span>
        </div>
        <InputFormat
          label="名前※"
          placeholder="予定テンプレート名"
          value={editData.name}
          validations={[templateNameValidation]}
          maxLength={100}
          size={COLUMN_SIZES.MIDDLE}
          onChange={value => onChange('name', value)}
          onValidate={onValidate}
        />

        <div className="d-flex mt-3">
          <Label md={COLUMN_SIZES.SHORT}>ID</Label>
          <div className="d-flex flex-column mx-3">
            <Label md={COLUMN_SIZES.MIDDLE} className="font-middle">
              {editData.id || '未保存'}
            </Label>
            <Label className=" text-gray-dark font-small">
              シフトインポート時等に予定テンプレートを指定するために必要になります｡
            </Label>
          </div>
        </div>
      </CardBody>

      <CardBody className="pt-0">
        <div className="text-nowrap overflow-auto border-end">
          <TimeScale is5min />
          <ShiftBar
            items={shiftBarItems}
            businessStartTime={businessStartTime}
            shiftBarWidth={businessDuration}
            onAdd={(startPos, endPos) => handleShiftBarAdd(startPos, endPos)}
            onChange={(idx, x, width) => handleShiftBarChange(idx, x, width)}
            disabled={partialScheduleTypes.length === 0 || partialWorkspaces.length === 0}
            is5min
          />
        </div>
      </CardBody>

      <CardBody>
        <CardTitle className="font-large fw-bold">予定テンプレートの削除</CardTitle>
        <Button outline color="danger" className="my-3" onClick={onDeleteButtonClick} disabled={disabledDeleteButton}>
          この予定テンプレートを削除
        </Button>
      </CardBody>
    </div>
  )
}
