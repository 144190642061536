import { createSlice } from '@reduxjs/toolkit'
import { sortBy } from 'es-toolkit'

import * as API from 'api/plans/plans'
import type {
  PlanCreateStatusResponse,
  PlanListResponse,
  PlanResponse,
  productivityAdjustments,
  ProductivityPlanResponse,
  UpdatePlanBulk,
  UpdatePlanResponse,
  UpdateProductivityPlan,
  UpdateTargetValue,
} from 'api/plans/types'

import { handleApiError, ERROR_STATUS_CODE, sleep } from 'slices/utils'

import type { Action, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type DailyPlanData = {
  scheduleTypeId: number
  scheduleTypeName: string
  plan: number
  target: number
}
export type DailyPlan = { data: DailyPlanData[]; workDate: string; workerCount: number }

type PlanListData = Omit<PlanListResponse, 'dailyPlans'> & {
  dailyPlans: DailyPlan[]
}

type PlanState = {
  isRequesting: boolean
  errorMessage: string
  plans?: PlanResponse
  planList?: PlanListData
  productivityAdjustments?: productivityAdjustments[]
  scheduleTruncated: boolean
}

export type UpdateProductivityPlanWithScheduleTypeId = UpdateProductivityPlan & {
  scheduleTypeId: number
}

const initialState: PlanState = {
  isRequesting: false,
  errorMessage: '',
  plans: undefined,
  planList: undefined,
  productivityAdjustments: undefined,
  scheduleTruncated: false,
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage
      state.plans = initialState.plans
      state.planList = initialState.planList
      state.productivityAdjustments = initialState.productivityAdjustments
      state.isRequesting = false
    },
    apiFailureUnclearPlans: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.errorMessage = action.payload.errorMessage
      state.isRequesting = false
    },
    getPlanListSuccess: (state, action: PayloadAction<PlanListResponse>) => {
      const dailyPlans = action.payload.dailyPlans
        .filter(dailyPlan => dailyPlan.workerCount > 0)
        .map(dailyPlan => {
          const data = action.payload.targetScheduleTypes.scheduleTypeIds.map((id, i) => ({
            scheduleTypeId: id,
            scheduleTypeName: action.payload.targetScheduleTypes.scheduleTypeNames[i],
            target: dailyPlan.targets[i],
            plan: dailyPlan.plans[i],
          }))
          return { ...dailyPlan, data }
        })
      state.planList = { ...action.payload, dailyPlans }
      state.isRequesting = false
    },
    updatePlanSuccess: (state, action: PayloadAction<UpdatePlanResponse>) => {
      state.scheduleTruncated = !!action.payload.results?.some(w => w.truncated)
      state.isRequesting = false
    },
    getPlanSuccess: (state, action: PayloadAction<PlanResponse>) => {
      const assignedGroups = sortBy(
        action.payload.groups.filter(group => group.groupName !== '未所属'),
        ['groupName']
      )
      const unassignedGroup = action.payload.groups.find(group => group.groupName === '未所属')
      const sortedGroups = (unassignedGroup ? [...assignedGroups, unassignedGroup] : assignedGroups).map(group => ({
        ...group,
        workersPlan: sortBy(group.workersPlan, ['workerName']),
      }))
      state.plans = {
        ...action.payload,
        groups: sortedGroups,
      }
      state.isRequesting = false
    },
    updatePlanAsyncSuccess: (state, action: PayloadAction<PlanCreateStatusResponse>) => {
      state.scheduleTruncated = !!action.payload.results?.some(w => w.truncated)
      state.isRequesting = false
    },
    getProductivityPlanSuccess: (state, action: PayloadAction<ProductivityPlanResponse>) => {
      state.productivityAdjustments = action.payload.productivityAdjustments
      state.isRequesting = false
    },
    requestFinished: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  apiFailureUnclearPlans,
  getPlanListSuccess,
  getPlanSuccess,
  updatePlanSuccess,
  updatePlanAsyncSuccess,
  getProductivityPlanSuccess,
  requestFinished,
} = plansSlice.actions

// Planデータを日付指定で取得
export const getPlanByDate =
  (workspaceId: number, workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getPlanByDate(workspaceId, workDate)
      dispatch(getPlanSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

// 取得したPlanデータでシフトがあるかのチェックをする
export const getPlanByDateWithShiftCheck =
  (workspaceId: number, workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getPlanByDate(workspaceId, workDate)
      const workersPlan = res.groups.flatMap(group => group.workersPlan)
      if (workersPlan.some(plan => plan.workShifts.some(shift => shift && shift > 0))) {
        dispatch(getPlanSuccess(res))
        return
      }

      // shiftのデータが存在しない場合
      dispatch(apiFailureUnclearPlans({ errorMessage: ERROR_STATUS_CODE.NOT_FOUND }))
    } catch (res) {
      // 作業計画画面から別の日付に移動する際に選択先の日付が存在しない場合､ エラー処理が実行される
      // plans が空になると作業計画画面が nodata になるのでここでは getPlanByDate がエラーでも plans を空にしない
      handleApiError(res as AxiosError, dispatch, apiFailureUnclearPlans)
    }
  }

// Planデータを日付、workerId指定で取得
export const getPlanByWorkerId =
  (workspaceId: number, workDate: string, workerId: number): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getPlanByWorkerId(workspaceId, workDate, workerId)
      dispatch(getPlanSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

// Planデータの一覧取得
export const getPlanList =
  (workspaceId: number, from?: string, to?: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getPlanList(workspaceId, from, to)
      dispatch(getPlanListSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

const bulkUpdatePlan = async (
  workspaceId: number,
  workDate: string,
  data: UpdatePlanBulk,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>
) => {
  const { requestId } = await API.updatePlanAsync(workspaceId, workDate, data)
  if (!requestId) {
    return new Error('requestId is not found')
  }

  const callGetPlanCreateStatus = async () => {
    const updateStatus = await API.getPlanCreateStatus(workspaceId, workDate, requestId)
    if (updateStatus.isCompleted) {
      dispatch(updatePlanAsyncSuccess(updateStatus))
      return
    }
    const retryInterval = updateStatus.retryInterval
    retryInterval > 0 && (await sleep(retryInterval))
    await callGetPlanCreateStatus()
  }

  await callGetPlanCreateStatus()
}

// Planデータ一括作成、更新
export const updatePlanBulkCreate =
  (workspaceId: number, workDate: string, data: UpdatePlanBulk): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await bulkUpdatePlan(workspaceId, workDate, data, dispatch)
      dispatch(requestFinished())
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }

// ProductivityPlanデータの一覧取得
export const getProductivityPlan =
  (workspaceId: number, workDate: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const res = await API.getProductivityPlan(workspaceId, workDate)
      dispatch(getProductivityPlanSuccess(res))
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

// PlanとProductivityPlanを更新
export const updateProductivityPlanAndPlanBulkCreate =
  (
    workspaceId: number,
    workDate: string,
    planData: UpdatePlanBulk | undefined,
    productivityPlanData: UpdateProductivityPlanWithScheduleTypeId[] | undefined
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    try {
      if (productivityPlanData) {
        await Promise.all(
          productivityPlanData.map(async data => {
            await API.updateProductivityPlan(workspaceId, workDate, data.scheduleTypeId, {
              productivityAdjustment: data.productivityAdjustment,
            })
          })
        )
      }

      if (!planData) {
        dispatch(requestFinished())
        return
      }

      await bulkUpdatePlan(workspaceId, workDate, planData, dispatch)
      dispatch(requestFinished())
    } catch (err) {
      handleApiError(err as AxiosError, dispatch, apiFailure)
    }
  }
// 作業種別の目標数作成、更新
export const updateTargetValue =
  (workspaceId: number, data: UpdateTargetValue): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      await API.updateTargetValue(workspaceId, data)
      dispatch(requestFinished())
    } catch (res) {
      handleApiError(res as AxiosError, dispatch, apiFailure)
    }
  }

export const importShift =
  (fileName: string, csvContent: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    try {
      const response = await API.shiftUploadUrl(fileName)
      await API.putUploadUrl(response.uploadUrl, csvContent)
      dispatch(requestFinished())
    } catch (error) {
      handleApiError(error as AxiosError, dispatch, apiFailure)
    }
  }
export const selectPlansStatus = (state: RootState) => ({ ...state.plans })

export default plansSlice.reducer
