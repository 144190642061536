import { useState } from 'react'
import Popup from 'reactjs-popup'
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

type ICON_TYPES =
  | 'save'
  | 'cal'
  | 'double_carot'
  | 'amount'
  | 'by_time'
  | 'compare'
  | 'delete'
  | 'edit'
  | 'filter'
  | 'Move'
  | 'others'
  | 'member-path1'
  | 'member-path2'
  | 'member-path3'
  | 'member-path4'
  | 'plus'
  | 'replace'
  | 'schedule'
  | 'search'
  | 'updated'
  | 'check'
  | 'hint'
  | 'info'
  | 'alert'
  | 'Menu'
  | 'carot_down'
  | 'carot_left'
  | 'carot_right'
  | 'carot_up'
  | 'chevron_left'
  | 'chevron_right'
  | 'setting'
  | 'template'
  | 'percent'
  | 'larger'
  | 'smaller'
  | 'downToUp'
  | 'upToDown'
  | 'leftToRight'
  | 'rightToLeft'
  | 'comment'
  | 'relation'
  | 'shift'

type CustomButtonProps = {
  children: string
  outline?: boolean
  icon?: ICON_TYPES
  iconPosition?: 'right' | 'left'
  disabled?: boolean
  size?: 'lg' | 'sm'
  className?: string
  onClick?: () => void
  tooltipText?: string
  name?: string // APMを判別するための文字列
  show?: boolean
}
type IconButtonProps = {
  outline?: boolean
  icon: ICON_TYPES
  disabled?: boolean
  size?: 'lg' | 'sm'
  className?: string
  onClick?: () => void
  tooltipText?: string
  name?: string // APMを判別するための文字列
  height?: number
}

export const CustomButton = ({
  children,
  outline = false,
  icon,
  iconPosition = 'left',
  disabled = false,
  size,
  className = '',
  onClick = () => null,
  name,
  show = true,
}: CustomButtonProps) => {
  const isLeft = iconPosition === 'left'
  const color = outline ? undefined : 'primary'

  if (!show) {
    return null
  }

  return (
    <div className={`bg-white rounded ${className}`}>
      <Button
        className="d-flex align-items-center"
        disabled={disabled}
        onClick={onClick}
        outline={outline}
        color={color}
        size={size}
        name={name}
      >
        {icon && isLeft && <i className={`icf-${icon} pe-2 font-large`} />}
        <div className="px-1">{children}</div>
        {icon && !isLeft && <i className={`icf-${icon} ps-2 font-large`} />}
      </Button>
    </div>
  )
}

export const IconButton = ({
  outline = false,
  icon,
  disabled = false,
  size,
  className = '',
  onClick = () => null,
  tooltipText = '',
  height = 28,
}: IconButtonProps) => {
  const color = outline ? undefined : 'primary'

  return (
    <div className={`bg-white rounded ${className}`}>
      {!disabled && tooltipText ? (
        <Popup
          trigger={
            <div>
              <Button
                className="d-flex align-items-center"
                onClick={onClick}
                outline={outline}
                color={color}
                size={size}
                style={{ height }}
              >
                <i className={`icf-${icon} font-large`} />
              </Button>
            </div>
          }
          position="bottom center"
          on="hover"
        >
          <span className="text-white bg-secondary p-1 px-3 rounded font-small">{tooltipText}</span>
        </Popup>
      ) : (
        <Button
          className="d-flex align-items-center"
          disabled={disabled}
          onClick={onClick}
          outline={outline}
          color={color}
          size={size}
          style={{ height }}
        >
          <i className={`icf-${icon} font-large`} />
        </Button>
      )}
    </div>
  )
}

type DropdownButtonProps = {
  buttonLabel: string
  onClickButton: () => void
  className?: string
  dropdownItems: { label: string; onClick: () => void }[]
  disabled?: boolean
}

export const DropdownButton = ({
  buttonLabel,
  onClickButton,
  className = '',
  dropdownItems,
  disabled = false,
}: DropdownButtonProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      className={`${className} bg-white`}
      disabled={disabled}
    >
      <Button outline onClick={onClickButton}>
        {buttonLabel}
      </Button>
      <DropdownToggle className="d-flex align-items-center rounded-end" outline>
        <i className="icf-carot_down font-large" />
      </DropdownToggle>
      <DropdownMenu>
        {dropdownItems.map(item => (
          <DropdownItem key={item.label} onClick={item.onClick}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default DropdownButton
