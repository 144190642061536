import { useEffect, useMemo, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Button, Label } from 'reactstrap'

import { selectSpotWorkerStatus, spotWorkersImport } from 'slices/spotWorkerSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import { CHARACTER_CODE_TYPES, COLUMN_SIZES } from 'components/common/constants'

import { useSpotWorkerCsv, type CharacterCodeItemType } from 'hooks/useSpotWorkerCsv'

type Props = {
  isOpen: boolean
  workDate: string
  onCancel: () => void
  onSuccess: () => void
}

export const SpotWorkerImportDialog = ({ isOpen, workDate, onCancel, onSuccess }: Props) => {
  const dispatch = useDispatch()
  const { characterCode, setCharacterCode, handleDownloadFormat, characterCodeItem } = useSpotWorkerCsv()
  const { isRequesting, errorMessage } = useSelector(selectSpotWorkerStatus, shallowEqual)
  const [submitted, setSubmitted] = useState(false)
  const [modelErrorMessage, setModelErrorMessage] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [isRegistration, setIsRegistration] = useState(false)

  const handleApprove = () => {
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('fileData', file)
    formData.append('registration', `${isRegistration}`)
    formData.append('fileName', file.name)
    formData.append('encoding', characterCode.value === CHARACTER_CODE_TYPES.SHIFT_JIS ? 'SJIS' : 'UTF8')

    dispatch(spotWorkersImport(workDate, formData))
    setSubmitted(true)
  }

  const handleCancel = () => {
    onCancel()
  }

  const disable = useMemo(() => !(file && /\.csv$/.test(file.name)), [file])

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModelErrorMessage('')
      onSuccess()
    } else {
      if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        return
      }
      setModelErrorMessage('保存できませんでした')
    }
    setSubmitted(false)
  }, [isRequesting, errorMessage, onSuccess, submitted])

  return (
    <CustomModal
      isOpen={isOpen}
      title="スポットメンバーインポート"
      approveDisabled={disable}
      errorMessage={modelErrorMessage}
      onCancel={handleCancel}
      onApprove={handleApprove}
    >
      <div className="d-flex flex-column row-gap-3">
        <div>
          下記スポットメンバーインポートデータフィーマットをダウンロードして、同じフォーマットでCSVアップロードをしてください。
        </div>
        <div>
          <SelectBoxFormat
            label="選択"
            value={characterCode.key}
            size={COLUMN_SIZES.MIDDLE}
            items={characterCodeItem}
            onChange={item => setCharacterCode(item as CharacterCodeItemType)}
            className="w-60"
          />
        </div>
        <ul className="mb-0">
          <li>
            スポットメンバーインポートについて詳しくは{' '}
            <a target="_blank" rel="noreferrer" href="https://help.smileboard.jp/uploading-worker-rosters">
              こちら
            </a>
            をご確認ください。
          </li>
          <li>データを更新する場合は現在のスポットメンバーをエクスポートし、編集してインポートしてください。</li>
          <li>
            一度にインポートできるのはヘッダーをのぞいて1000行までです。適宜分割してインポートしてください。
            <span className="text-danger">
              アップロードされたCSVファイルがヘッダーをのぞいて1000行より多い場合は処理は実施されません。
            </span>
          </li>
        </ul>
        <div>
          <Button color="link" className="shadow-none text-decoration-none ps-0" onClick={handleDownloadFormat}>
            スポットメンバーインポートデータフォーマットをダウンロード
          </Button>
        </div>
        <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
        <div>
          条件を満たしているスポットメンバーはインポートと同時に登録または更新を完了することができます。条件に関しては
          <a target="_blank" rel="noreferrer" href="https://help.smileboard.jp/guidelines-for-roster-uploads">
            こちら
          </a>
          をご確認ください。
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="importSetting"
            checked={isRegistration}
            onChange={e => {
              setIsRegistration(e.currentTarget.checked)
              setModelErrorMessage('')
            }}
          />
          <Label className="form-check-label" for="importSetting">
            インポートと同時に登録・更新を完了する
          </Label>
        </div>
      </div>
    </CustomModal>
  )
}
