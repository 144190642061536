import { range } from 'es-toolkit'
import moment from 'moment'

export const PERIOD_LIST = {
  USER_SETTING: 0,
  DAYS_7: 7,
  DAYS_15: 15,
  DAYS_30: 30,
  DAYS_45: 45,
  DAYS_90: 90,
}

export type PeriodListType = {
  label: string
  periodStart: Date
  periodEnd: Date
  id: string
}

export const createXAxis = (start: Date, end: Date): string[] => {
  const from = moment(start).startOf('date')
  const to = moment(end).startOf('date')
  return range(0, to.diff(from, 'days') + 1).map(amount => from.clone().add(amount, 'days').format('YYYY-MM-DD'))
}
