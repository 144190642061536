import { intersection } from 'es-toolkit'
import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectGroupsStatus } from 'slices/groupsSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'

import { FilteringButton, FilteringInputField } from 'components/common'
import type { FilterItem } from 'components/common/types'
import { isFilteredWorker } from 'components/common/utils'

import styles from './AssignmentWorkerFilter.module.scss'

import type { ScheduleEditType } from './DroppingWorkerCards'
import type { Dispatch, SetStateAction } from 'react'

type Props = {
  initSchedules: ScheduleEditType[]
  onChange: Dispatch<SetStateAction<ScheduleEditType[]>>
  setShouldReset: Dispatch<SetStateAction<boolean>>
  shouldReset: boolean
}

const BLANK_ID = -1

const AssignmentWorkerFilter = ({ initSchedules, onChange, setShouldReset, shouldReset }: Props) => {
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const [filterWord, setFilterWord] = useState('')
  const [selectedFilterGroups, setSelectedFilterGroups] = useState<number[]>([])
  const [selectedFilterSkills, setSelectedFilterSkills] = useState<number[]>([])

  useEffect(() => groups && setSelectedFilterGroups(groups.map(g => g.id).concat(BLANK_ID)), [groups])

  useEffect(() => skills && setSelectedFilterSkills(skills.map(s => s.id).concat(BLANK_ID)), [skills])

  useEffect(() => {
    if (!shouldReset) {
      return
    }
    groups && setSelectedFilterGroups(groups.map(g => g.id).concat(BLANK_ID))
    skills && setSelectedFilterSkills(skills.map(s => s.id).concat(BLANK_ID))
    setFilterWord('')
    setShouldReset(false)
  }, [groups, skills, shouldReset, setShouldReset])

  const filterGroupItems = useMemo<FilterItem[]>(
    () =>
      groups
        .map(g => ({ key: g.id, label: g.name, checked: selectedFilterGroups.includes(g.id) }))
        .concat({ key: BLANK_ID, label: '未所属', checked: selectedFilterGroups.includes(BLANK_ID) }),
    [groups, selectedFilterGroups]
  )

  const filterSkillItems = useMemo<FilterItem[]>(
    () =>
      skills
        .map(s => ({ key: s.id, label: s.name, checked: selectedFilterSkills.includes(s.id) }))
        .concat({ key: BLANK_ID, label: '未設定', checked: selectedFilterSkills.includes(BLANK_ID) }),
    [skills, selectedFilterSkills]
  )

  const selectedGroupNames = useMemo(() => {
    const names = groups.filter(g => selectedFilterGroups.includes(g.id)).map(g => g.name)
    selectedFilterGroups.includes(BLANK_ID) && names.push('未所属')
    return names
  }, [selectedFilterGroups, groups])

  useEffect(
    () =>
      onChange(prev => {
        const editWorkers = prev.flatMap(s => s.workers.map(w => ({ ...w, type: s.type })))
        return initSchedules.map(s => {
          const workers = s.workers
            .filter(w => !editWorkers.some(e => e.workerId === w.workerId))
            .concat(editWorkers.filter(w => w.type === s.type))
            .map(w => {
              const hasGroups = selectedGroupNames.includes(w.groupName || '未所属')
              const hasBlankSkill = selectedFilterSkills.includes(BLANK_ID) && w.skillIds?.length === 0
              const hasSelectedSkill = hasBlankSkill || intersection(selectedFilterSkills, w.skillIds || []).length > 0
              const hasFilterWord = isFilteredWorker(filterWord, {
                name: w.name,
                wmsMemberId: w.wmsMemberId,
                workerType: w.workerType,
              })

              return { ...w, visible: hasGroups && hasSelectedSkill && hasFilterWord }
            })
          return { ...s, workers }
        })
      }),
    [selectedGroupNames, selectedFilterSkills, filterWord, onChange, initSchedules]
  )

  return (
    <div className={`d-flex mb-3 ${styles.filterButtonGroup}`}>
      <div className="w-25">
        <FilteringInputField onChange={setFilterWord} value={filterWord} />
      </div>
      <FilteringButton
        items={filterGroupItems}
        onChange={setSelectedFilterGroups}
        value={selectedFilterGroups}
        label="グループで絞り込み"
        disabled={groups.length === 0}
      />
      <FilteringButton
        items={filterSkillItems}
        onChange={setSelectedFilterSkills}
        value={selectedFilterSkills}
        label="スキルで絞り込み"
        disabled={skills.length === 0}
      />
    </div>
  )
}
export default AssignmentWorkerFilter
