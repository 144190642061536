import dayjs from 'dayjs'
import { encode } from 'iconv-lite'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Button, FormGroup, Input, Label } from 'reactstrap'

import { WORKER_TYPES } from 'api/workers/constants'
import type { WorkerType } from 'api/workers/types'

import { showError } from 'slices/notificationSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'slices/utils'
import { selectWorkersStatus, importWorkers, exportWorkers, resetExportDataUrl } from 'slices/workersSlice'

import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import { CHARACTER_CODE_TYPES, COLUMN_SIZES, WORKER_TYPES_LABEL } from 'components/common/constants'
import type { CharacterCodeType } from 'components/common/types'
import { downloadByURL, exportData } from 'components/common/utils'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

type CharacterCodeItemType = { key: CharacterCodeType; value: CharacterCodeType }

const MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES = [
  '名前',
  'メンバーID',
  '職掌',
  'スキル設定',
  'メンバー削除',
]
const MAX_MEMBER_IMPORT_COLUMNS = 2000

const characterCodeItem: CharacterCodeItemType[] = [
  { key: CHARACTER_CODE_TYPES.SHIFT_JIS, value: CHARACTER_CODE_TYPES.SHIFT_JIS },
  { key: CHARACTER_CODE_TYPES.UTF8, value: CHARACTER_CODE_TYPES.UTF8 },
]

const WorkersImportDialog = ({ isOpen, onSuccess, onCancel }: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string>()
  const [file, setFile] = useState<File | null>(null)
  const [characterCode, setCharacterCode] = useState(characterCodeItem[0])
  const [selectedWorkerType, setSelectedWorkerType] = useState<WorkerType>(WORKER_TYPES.REGULAR_MEMBER)
  const { isRequesting, errorMessage, downloadUrl } = useSelector(selectWorkersStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const dispatch = useDispatch()

  const disabled = useMemo(() => !(file && /\.csv$/i.test(file.name)), [file])

  const handleSaveClick = () => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    if (characterCode.value === CHARACTER_CODE_TYPES.SHIFT_JIS) {
      reader.readAsText(file, 'SJIS')
    } else {
      reader.readAsText(file)
    }
    reader.addEventListener('load', () => {
      const content = reader.result
      if (content) {
        dispatch(importWorkers(file.name, selectedWorkerType, content.toString()))
        setSubmitted(true)
      } else {
        setModalErrorMessage('ファイルが空です。')
      }
    })
    reader.addEventListener('error', () => setModalErrorMessage('ファイルの読み込みに失敗しました。'))
    setSelectedWorkerType(WORKER_TYPES.REGULAR_MEMBER)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      setModalErrorMessage('エラーが発生しました。')
    }

    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const handleDownloadFormat = () => {
    if (selectedWorkerType === WORKER_TYPES.REGULAR_MEMBER) {
      const data = {
        workerType: selectedWorkerType,
        replaceAverageProductivity: false,
        includeUnaffiliatedWorker: false,
        targetWorkspaceIds: [],
      }
      dispatch(exportWorkers(data))
      return
    }

    const csvAllScheduleTypesCsvHeader = allScheduleTypes.map(s => `人時生産性_${s?.name}_${s?.workspaceName}[${s.id}]`)

    MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES.splice(
      MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES.length - 1,
      0,
      ...csvAllScheduleTypesCsvHeader
    )
    const formatHeader = [MEMBER_IMPORT_SPOT_WORKER_CSV_HEADER_WITHOUT_SCHEDULE_TYPES]
      .map(row => row.join(','))
      .join('\n')
    const csvData = encode(formatHeader, characterCode.key)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const today = dayjs()
    exportData(blob, `Smile Board Member ${today.month() + 1} ${today.date()} ${today.year()}.csv`)
  }

  const downloadCsv = useCallback(
    async (url: string, filename: string) => {
      try {
        await downloadByURL(url, filename)
      } catch {
        dispatch(showError())
      } finally {
        dispatch(resetExportDataUrl())
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (!downloadUrl) {
      return
    }

    const filename = `members-${moment().format('YYYY-MM-DD-HHmm')}.csv`
    downloadCsv(downloadUrl, filename)
  }, [downloadUrl, downloadCsv])

  const WorkerTypeText = useMemo(
    () => (selectedWorkerType === WORKER_TYPES.REGULAR_MEMBER ? 'レギュラーメンバー' : 'スポットメンバー'),
    [selectedWorkerType]
  )

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーインポート"
      approveLabel="CSVインポート"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
      submitName="member-import-submit"
    >
      <div className="pb-4">
        <div>
          <div className="my-2 fw-bold">メンバー属性の選択</div>
          <div className="mb-2">メンバーエクスポートするメンバー属性を選択してください</div>
          <FormGroup check className="d-flex flex-column row-gap-2">
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={selectedWorkerType === WORKER_TYPES.REGULAR_MEMBER}
                onChange={() => setSelectedWorkerType(WORKER_TYPES.REGULAR_MEMBER)}
              />
              {WORKER_TYPES_LABEL.find(w => w.key === WORKER_TYPES.REGULAR_MEMBER)?.value}
            </Label>
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="me-2"
                checked={selectedWorkerType === WORKER_TYPES.SPOT_MEMBER}
                onChange={() => setSelectedWorkerType(WORKER_TYPES.SPOT_MEMBER)}
              />
              {WORKER_TYPES_LABEL.find(w => w.key === WORKER_TYPES.SPOT_MEMBER)?.value}
            </Label>
          </FormGroup>
        </div>
        <div className="my-3">
          {`下記${WorkerTypeText}インポートデータフォーマットをダウンロードして、同じフォーマットでCSVアップロードをしてください。`}
        </div>
        <SelectBoxFormat
          label="文字コード選択"
          value={characterCode.key}
          size={COLUMN_SIZES.MIDDLE}
          items={characterCodeItem}
          onChange={item => setCharacterCode(item as CharacterCodeItemType)}
          className="w-60 mb-3"
        />
        <ul className="mb-3">
          <li>
            メンバーインポートについて詳しくは
            <a
              className="text-decoration-none"
              target="_blank"
              rel="noreferrer"
              href="https://help.smileboard.jp/member-import"
            >
              こちら
            </a>
            をご確認ください。
          </li>
          <li>データを更新する場合は現在のメンバーマスタをエクスポートし、編集してインポートしてください。</li>
          <li>
            {`一度にインポートできるのはヘッダーを除いて${MAX_MEMBER_IMPORT_COLUMNS}
            行までです。適宜分割してインポートしてください。`}
            <span className="text-danger">
              {`アップロードされたCSVファイルがヘッダーを除いて${MAX_MEMBER_IMPORT_COLUMNS}行より多い場合は処理は実施されません。`}
            </span>
          </li>
        </ul>
        <Button color="link" className="mb-3 ps-0 shadow-none text-decoration-none" onClick={handleDownloadFormat}>
          {`${WorkerTypeText}インポートデータフォーマットをダウンロード`}
        </Button>
        <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
      </div>
    </CustomModal>
  )
}

export default WorkersImportDialog
