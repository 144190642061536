import dayjs from 'dayjs'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { ModalBody, ModalFooter, Button, CardBody, CardText, FormGroup, Label, Col } from 'reactstrap'

import { Modal, TimeSelect } from 'components/common'
import { TENTATIVE_SCHEDULE_TYPE_ID } from 'components/common/constants'
import { getRandomNumber } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import type { EditGroupsType, WorkPlanSchedulesType } from '../types'

export const deleteWorkerSchedules = (startAt: string, duration: number, schedules: WorkPlanSchedulesType[]) => {
  const deleteStart = moment(startAt)
  const deleteEnd = moment(startAt).add(duration, 'seconds')

  const shifts = schedules.filter(s => s.scheduleTypeId === TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
  const updatedSchedules = schedules
    .filter(s => s.scheduleTypeId !== TENTATIVE_SCHEDULE_TYPE_ID.SHIFT)
    .reduce<WorkPlanSchedulesType[]>((acc, cur) => {
      const curstart = moment(cur.startAt)
      const curend = moment(cur.startAt).add(cur.duration, 'seconds')

      if (
        deleteStart.isBetween(curstart, curend, 'minute', '()') &&
        deleteEnd.isBetween(curstart, curend, 'minute', '()')
      ) {
        // current schedule の中に delete 時間が全て含まれているとき
        // current schedule を分割
        acc.push({ ...cur, duration: cur.duration - (curend.unix() - deleteStart.unix()) })
        acc.push({
          ...cur,
          scheduleId: getRandomNumber(),
          startAt: deleteEnd.format(),
          duration: cur.duration - (deleteEnd.unix() - curstart.unix()),
        })
      } else if (deleteStart.isBetween(curstart, curend, 'minute', '(]')) {
        // current schedule の中に delete の開始時間のみが含まれているとき
        // current schedule の後半部分を削除
        const diff = curend.unix() - deleteStart.unix()
        acc.push({ ...cur, duration: cur.duration - diff })
      } else if (deleteEnd.isBetween(curstart, curend, 'minute', '[)')) {
        // current schedule の中に delete の終了時間のみが含まれているとき
        // current schedule の前半部分を削除
        const diff = deleteEnd.unix() - curstart.unix()
        acc.push({ ...cur, startAt: deleteEnd.format(), duration: cur.duration - diff })
      } else if (
        curstart.isBetween(deleteStart, deleteEnd, 'minute', '[]') &&
        curend.isBetween(deleteStart, deleteEnd, 'minute', '[]')
      ) {
        // delete 時間の中に current schedule が含まれているとき
        // current schedule は全て削除
      } else {
        // current schedule と delete 時間の重複がない場合はそのまま返す
        acc.push(cur)
      }
      return acc
    }, shifts)

  return updatedSchedules
}

type Props = {
  isOpen: boolean
  workerIds: number[]
  editGroups: EditGroupsType[]
  workDate?: string
  setEditGroups: (items: EditGroupsType[]) => void
  onCancel: () => void
}

const DeleteSchedules = (props: Props) => {
  const { isOpen, workerIds, editGroups, workDate, setEditGroups, onCancel } = props
  const [validTime, setValidTime] = useState(true)
  // 直後の予定開始時刻を設定
  const [startTime, setStartTime] = useState('00:00')

  // 直後の予定開始時刻の１時間後を設定
  const [endHour, setEndHour] = useState('00')
  const [endMinute, setEndMinute] = useState('00')

  const { timeRange, getHourOver24h, businessEndTime, flooredCurrentTime } = useBusinessTime()

  useEffect(() => {
    setValidTime(true)
    setStartTime(flooredCurrentTime)
    const [currentHour, currentMinute] = flooredCurrentTime.split(':')
    // endHourは、24時間以降を考慮したstartHourに、数値計算上+1した値とする。
    const calcEndHour = ('00' + (Number(currentHour) + 1).toString()).slice(-2)
    const calcEndMinute = ('00' + currentMinute).slice(-2)

    const [businessEndHour, businessEndMinute] = businessEndTime.split(':').map(Number)

    // 営業終了時間とcalcEndHour・calcEndMinuteを比較し、短い方をendHour・endMinuteに代入する
    if (
      businessEndHour < Number(calcEndHour) ||
      (businessEndHour === Number(calcEndHour) && businessEndMinute <= Number(calcEndMinute))
    ) {
      setEndHour(('00' + businessEndHour).slice(-2))
      setEndMinute(('00' + businessEndMinute).slice(-2))
    } else {
      setEndHour(calcEndHour)
      setEndMinute(calcEndMinute)
    }
  }, [isOpen, getHourOver24h, businessEndTime, flooredCurrentTime])

  const onDeleteClick = () => {
    // 予定を削除ボタンを押した時の処理
    // 開始時間が終了時関より前
    const valid = dayjs(`${workDate} ${startTime}`).isBefore(dayjs(`${workDate} ${endHour}:${endMinute}`))
    setValidTime(valid)

    if (valid) {
      // 時刻判定が valid だった時該当予定を削除する
      const startAt = dayjs(`${workDate} ${startTime}`).utc().format()
      const duration = dayjs(`${workDate} ${endHour}:${endMinute}`).utc().unix() - dayjs(startAt).unix()
      const newEditSchedules = editGroups.map<EditGroupsType>(group => {
        const workers = group.workers.map(groupWorker => {
          // 選択されてないworkerの場合は除外
          if (!workerIds.includes(groupWorker.workerId)) {
            return groupWorker
          }
          const schedules = deleteWorkerSchedules(startAt, duration, groupWorker.schedules)
          return { ...groupWorker, schedules }
        })
        return { ...group, workers }
      })
      setEditGroups(newEditSchedules)
      onCancel()
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalBody className="font-large fw-bold">予定の一括削除</ModalBody>
      <ModalBody className="font-small py-0">
        <CardBody className="mb-3">
          <CardText>
            選択されているメンバーに入力されている予定を一括削除します。保存するまで反映はされません。
          </CardText>
        </CardBody>
        <CardBody className="py-0 mt-3">
          <FormGroup row>
            <Label md={3}>予定一括削除対象時間</Label>
            <Col md={8}>
              <TimeSelect
                hour={startTime.split(':')[0]}
                minute={startTime.split(':')[1]}
                label="から"
                onChange={(hour, minute) => setStartTime(`${hour}:${minute}`)}
                range={timeRange.start}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3}></Label>
            <Col md={8}>
              <TimeSelect
                hour={endHour}
                minute={endMinute}
                label="まで"
                onChange={(hour, minute) => {
                  setEndHour(hour)
                  setEndMinute(minute)
                }}
                range={timeRange.end}
              />
            </Col>
          </FormGroup>
          {!validTime && <CardText className="text-danger">終了時刻は開始時刻より後の時刻を設定してください</CardText>}
        </CardBody>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button outline onClick={onCancel}>
          キャンセル
        </Button>
        <Button color="primary" className="px-4" onClick={onDeleteClick}>
          予定を削除
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteSchedules
