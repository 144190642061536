import moment from 'moment'
import { Button, Row, Col, CardBody } from 'reactstrap'

import styles from './SubmitFooter.module.scss'

type Props = {
  updatedBy?: string | null
  updatedAt?: string
  cancelDisabled?: boolean
  submitDisabled?: boolean
  submitLabel?: string
  onSubmit: () => void
  onCancel: () => void
  submitName?: string // APMを判別するための文字列
}

export const SubmitFooter = ({
  updatedBy,
  updatedAt,
  cancelDisabled = false,
  submitDisabled = false,
  submitLabel = '保存',
  onSubmit,
  onCancel,
  submitName,
}: Props) => {
  return (
    <div className={`bg-white border-top position-sticky d-flex justify-content-between p-3 ${styles.container}`}>
      <div className="flex-grow-1">
        <Button outline disabled={cancelDisabled} onClick={onCancel}>
          キャンセル
        </Button>
      </div>
      {updatedBy && updatedAt && (
        <Row xs="1" className="font-x-small text-muted text-end w-50">
          <Col className="text-truncate">最終編集：{updatedBy}</Col>
          <Col>{moment(updatedAt).local().format('YYYY年MM月DD日 HH:mm')}</Col>
        </Row>
      )}
      <Button color="primary" disabled={submitDisabled} className="px-4 ms-3" onClick={onSubmit} name={submitName}>
        {submitLabel}
      </Button>
    </div>
  )
}

export const CardSubmitFooter = ({
  updatedBy,
  updatedAt,
  cancelDisabled = false,
  submitDisabled = false,
  onSubmit,
  onCancel,
  submitName,
}: Props) => {
  return (
    <>
      <hr className="m-0"></hr>
      <CardBody className="d-flex">
        <div className="flex-grow-1">
          <Button outline disabled={cancelDisabled} onClick={onCancel}>
            キャンセル
          </Button>
        </div>
        {updatedBy && updatedAt && (
          <Row xs="1" className="font-x-small text-muted text-end w-50">
            <Col className="text-truncate">最終編集：{updatedBy}</Col>
            <Col>{moment(updatedAt).local().format('YYYY年MM月DD日 HH:mm')}</Col>
          </Row>
        )}
        <Button color="primary" disabled={submitDisabled} className="px-4 ms-3" onClick={onSubmit} name={submitName}>
          保存
        </Button>
      </CardBody>
    </>
  )
}
