import { Input, Label } from 'reactstrap'

import { Chart, BadgeLabel } from 'components/common'

import type { Options } from 'highcharts'

type Props = {
  chartOptions?: Options
  checked: boolean
  onChange: (value: boolean) => void
}

export const BopMonitoringChartLaborCostsBalance = ({ chartOptions, checked, onChange }: Props) => {
  if (!chartOptions) {
    return null
  }

  return (
    <>
      <Input
        className="form-check-input me-2"
        type="checkbox"
        onChange={e => onChange(e.target.checked)}
        checked={checked}
      />
      <Label className="form-check-label mb-4">割合表示</Label>
      <Chart options={chartOptions} />
      <div className="d-flex">
        <BadgeLabel label="変動直接労務費" color="danger-stronger-middle" />
        <BadgeLabel label="変動間接労務費" color="danger-middle" />
        <BadgeLabel label="間接労務費" color="danger-pale" />
      </div>
    </>
  )
}
